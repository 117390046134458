._my-store-details {
  // width: 100%;
  margin: 40px;

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    ._hold-go-back-name-of-page {
      display: flex;
      align-items: center;

      ._back-btn {
        background-color: transparent;
        border: none;
        outline: none;
        margin-right: 15px;

        &:focus {
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
        }

        &:hover {
          // background-color: transparent;
          box-shadow: none;
        }
      }

      h2 {
        font-size: 34px;
        color: #16284d;
        margin-right: 16px;
      }

      span {
        font-size: 22px;
        color: #777c85;
      }
    }

    ._save {
      background-color: #3ed0a3;
      width: 190px;
      height: 48px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      border-radius: 24px;
      border: none;

      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  ._hold-my-store-details {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 40px;

    ._profile-picture {
      ._flex-end {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        .imgIcon {
          width: 130px;
          height: 130px;
          background-color: #9aa1af23;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .img {
            width: 130px;
            height: 130px;
            border-radius: 15px;
          }

          .icon {
            color: #16284dc9;
            width: 100px;
            height: 100px;
          }
        }
      }
    }

    ._user-information {
      margin-top: 40px;
      // margin-right: 40px;
      width: 90%;
      padding: 5px 20px;

      .tipBox {
        padding: 20px;
        border: 0.3px dotted #3ed0a3;
        border-radius: 15px;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .blogBox {
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 20px;
        border: 0.3px dotted #3ed0a3;
        border-radius: 15px;
        margin: 10px 0;

        display: grid;
        grid-template-columns: 2fr 3fr 1fr;
        .imgBoX {
          width: 100%;
          height: 250;
          border-radius: 15px;

          .bImg {
            width: 100%;
            height: 250;
            border-radius: 15px;
          }
        }
      }

      .imageBox {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        align-items: center;
        .imgIcon {
          width: 200px;
          height: 200px;
          background-color: #9aa1af23;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .img {
            width: 200px;
            height: 200px;
            border-radius: 15px;
          }

          .icon {
            color: #16284dc9;
            width: 100px;
            height: 100px;
          }
        }
      }

      .btn-holder {
        justify-content: center;
        align-items: center;
        display: flex;

        ._add-new-item {
          width: 60%;
          height: 48px;
          display: flex;

          align-items: center;
          justify-content: center;
          background: #16284d;
          border-radius: 24px;
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          border: none;
          margin-bottom: 20px;
        }
      }
      .btn-holder2 {
        justify-content: center;
        align-items: center;
        display: flex;

        ._add-new-item {
          width: 40%;
          height: 48px;
          display: flex;

          align-items: center;
          justify-content: center;
          background: #16284d;
          border-radius: 24px;
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          border: none;
          margin-bottom: 20px;
        }
      }

      h2 {
        font-size: 20px;
        color: #16284d;
        margin-bottom: 18px;
      }

      /// form inputss
      .form-label {
        font-size: 14px;
        color: #9aa1af;
      }

      .form-group {
        border-bottom: 1px solid #dcdee3;
        margin-bottom: 32px;
      }

      .form-control {
        color: #16284d;
        font-size: 18px;
        border: none;
        outline: none;
        padding-left: 0;
        font-weight: normal;
        padding-bottom: 17px;

        &:focus {
          box-shadow: none;
          outline: none;
          border: none;
        }

        &::placeholder {
          font-size: 18px;
          color: #9aa1af;
          font-weight: normal;
        }
      }
    }
  }

  ._hold-info-delivery-option {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    ._delivery-payment-option {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    ._delivery-options {
      width: 100%;

      h3 {
        font-size: 20px;
        color: #16284d;
        font-weight: bold;
        margin-bottom: 25px;
      }

      ._courier-service {
        button {
          width: 100%;
          height: 40px;
          background: #16284d;
          border-radius: 20px;
          border: none;
          font-size: 14px;
          color: #fff;
          font-weight: bold;
        }

        width: 100%;
        background: #ffffff;
        border: 1px solid #dcdee3;
        border-radius: 23px;
        padding: 20px;

        ._name {
          display: grid;
          grid-template-columns: 3fr 1fr 1fr;
          gap: 5px;
          align-items: center;
          background: #f6f7f8;
          border-radius: 12px;
          padding: 0 20px;
          height: 82px;
          margin-bottom: 20px;

          ._title {
            h5 {
              font-size: 16px;
              color: #16284d;
              font-weight: bold;
              margin-bottom: 5px;
            }

            ._type {
              p {
                font-size: 14px;
                color: #777c85;
                margin: 0;

                b {
                  margin: 0 5px;
                }
              }
            }
          }

          ._remove {
            border: none;
            background-color: transparent;
            font-size: 14px;
            font-weight: bold;
            color: #f2482f;
          }
        }

        ._add-another-option {
          button {
            width: 100%;
            height: 48px;
            background-color: #16284d;
            border-radius: 24px;
            border: none;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
          }
        }
      }
    }

    ._payment-option {
      width: 100%;
      margin-top: 40px;
    }
  }
}
