._hold-store-management-info {
  border: 1px solid #dcdee3;
  border-radius: 28px;
  width: 350px;
  height: auto;
  margin-right: 24px;
  padding: 24px;

  ._pic {
    background: #ebebe9;
    border: 1px solid #dcdee3;
    border-radius: 16px;
    img {
      border-radius: 16px;
      width: 100%;
    }
  }
  &:last-child {
    margin-right: 0;
  }

  ._info {
    text-align: left;
    margin-top: 22px;
    h3 {
      font-size: 16px !important;
      color: #16284d;
      font-weight: bold;
    }

    ._hold-buttons {
      margin-top: 20px;
      button {
        width: auto;
        padding: 3px 16px;
        font-size: 16px;
        font-weight: normal;
        border-radius: 15px;
        margin-right: 24px;
        // if online color: #3ED0A3

        &:last-child {
          margin-right: 0;
        }

        &._website {
          border: none;
          color: #fff;
          background: #16284d;
        }
        &._on-building {
          color: #fff;
        }
        &.active {
          background-color: #28a745;
          border: 1px solid #28a745;
        }
        &.in-active {
          background-color: #dc3545;
          border: 1px solid #dc3545;
        }
      }
    }
  }
}
