._edit-account-modal {
  .modal-content {
    width: 460px;
    height: auto;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 24px;
    position: relative;
    padding: 17px;
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: none;
      h2 {
        font-size: 22px;
        color: #16284d;
        font-weight: bold;
      }

      button {
        background-color: transparent;
        border: none;
      }
    }

    ._edit-content {
      ._profile-picture {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        ._image {
          width: 122px;
          height: 122px;
          border-radius: 50%;
          img {
            width: 122px;
            height: 122px;
            border-radius: 50%;
          }
        }
        .act__ {
          button {
            width: 164px;
            height: 34px;
            background-color: #16284d;
            border-radius: 20px;
            border: none;
            margin-top: 30px;

            font-size: 15px;
            color: #fff;
            font-weight: normal;
          }
        }
      }
    }
  }

  ._edit-form {
    .form-group {
      border-bottom: 1px solid #dcdee3;
      margin-bottom: 25px;
      .form-label {
        font-size: 14px;
        color: #9aa1af;
        font-weight: normal;
      }

      .form-control {
        font-size: 18px;
        color: #16284d;
        border: none;
        outline: none;
        padding-left: 0;
        padding-bottom: 18px;
        &::placeholder {
          color: #9aa1af;
          font-size: 16px;
          font-weight: normal;
        }
        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }

    button {
      width: 100%;
      height: 48px;
      margin-top: 10px;
      background-color: #3ed0a3;
      border: none;

      border-radius: 24px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }
}

._edit-profile {
  background-color: transparent;
  color: #16284d;
  font-weight: 600;
  border: none;
  font-size: 18px;
}

._change1{
  background-color:'#3ed0a3';
}

