@use "../../../../assets/styles/variables";

.oss-primary-btnd {
  background-color: variables.$danger-color;
  border-color: variables.$danger-color;
  border-style: none;
  color: #fff;
  border-radius: 24px;
  padding: 0.6rem 0 0.6rem 0;
  font-family: variables.$font-family;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;

  &:hover {
    color: #fff;
  }
}

.price-min-max {
  background: #f6f7f8;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.175px;
  color: #9aa1af;
  border: none;
}

input.form-control {
  background: #f6f7f8;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.175px;
  color: #9aa1af;
  border: none;
}

.filter-btn-order {
  background: #f6f7f8;
  border-radius: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.175px;
  color: #9aa1af;

  &.selected {
    background-color: #16284d;
    color: #fff;
  }
}

.filter-drawer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.275px;
  color: #16284d;
}

.custom-filter-wrapperf {
  width: 68px;
  margin-right: 10px;
  position: relative;
  z-index: 9999999;
}

._open-filter {
  border: none;
  outline: none;
  background: none !important;
}

.topRowFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.275px;
  color: #16284d;

  .applyFiltersBox {
    width: 125px;
    height: 35px;
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    border: none;
    border-radius: 20px;

    background-color: #16284d;
    font-weight: 600;

    &:disabled {
      background-color: #d7dde9;
      color: #000;
      cursor: not-allowed;
    }
  }
}

.filter-container {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);

  .filter-form-container {
    padding: 0;
    border: none !important;
    border-radius: 15px 0 0 15px;
    width: 450px;

    #filter-form {
      padding: 1.5rem 0;
      box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
      border-radius: 15px 0 0 15px;
      background-color: #fff;

      height: 70vh;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        padding: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 24px;
        margin: 0 2px;

        width: 5px;
        background-color: rgb(219, 216, 221);
      }

      .close-sort-container {
        border-radius: 8px;
        width: 28px;
        height: 28px;
        text-align: center;
        cursor: pointer;
        background-color: #e2f8f1;
      }
    }
  }
}
