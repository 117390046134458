#display-image {
  width: 400px;
  height: 225px;
  border: 1px solid black;
  background-position: center;
  background-size: cover;
}

.button-save {
  background: #dcdee3;
  border-radius: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #9aa1af;
  width: 100%;
}

.feild-area-bootstrap {
  background: #f6f7f8;
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #9aa1af;
  // outline: none ;
  border:none;

}

.image-upload{
  background: #F6F7F8;
  border-radius: 6px;
  height: 326px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .add-image{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.175px;

    color: #16284D;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 8px 15px;
    height: 34px;
  }
  
}

.blog-image-bottom-text{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.175px;
  color: #9AA1AF;
  margin-top: 10px;
}

.button-container{
  cursor: pointer;
  padding: 8px 10px;
  width: 217px;
  background: #3ED0A3;
  border-radius: 24px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.create-new-button{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}
