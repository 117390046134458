._your-subs {
  // margin-top: 40px;
  width: 45%;
  ._holder {
    padding: 24px;
    border: 1px solid #dcdee3;
    border-radius: 28px;

    ._header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 16px;
        color: #16284d;
        font-weight: bold;
      }
      ._change {
        font-size: 14px;
        font-weight: normal;
        color: #fff;
        width: 107px;
        height: 34px;
        background-color: #16284d;
        border-radius: 20px;
        border: none;
      }
    }

    ._box-package {
      width: 100%;
      height: 151px;
      padding: 32px;
      margin-top: 24px;
      background: #f6f7f8;
      border-radius: 13px;
      display: flex;
      align-items: center;

      ._title-package {
        margin-left: 20px;
        h3 {
          font-size: 18px;
          color: #16284d;
          font-weight: bold;
        }
        ._plan-benefits {
          display: flex;
          align-items: center;
          p {
            font-size: 14px;
            color: #3e4452;
            font-weight: normal;
            margin: 0;
          }
          span {
            margin: 0 7px;
          }
          ._see-benefit {
            padding: 0;
            margin: 0;
            background-color: transparent;
            font-size: 14px;
            font-weight: bold;
            color: #16284d;
            border: none;
          }
        }
      }
    }
  }
}
