._delete-modal {
  .modal-content {
    width: 466px;
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 24px;
    padding: 0 15px;
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: none;
      padding: 20px;

      h1 {
        font-size: 18px;
        color: #16284d;
        font-weight: bold;
      }

      button {
        border: none;
        background: transparent;
      }
    }

    .modal-body {
      display: flex;
      justify-content: center;

      ._hold-content {
        text-align: center;
        width: 100%;

        h2 {
          font-size: 18px;
          color: #16284d;
          font-weight: bold;
        }

        .search-bar {
          width: 100%;
          height: 48px;
          background: #f6f7f8;
          border-radius: 12px;
          margin: 10px auto;
          border: 0.3px dashed #16284d;
          input {
            background: none;
            border: none;
            outline: none;
            height: 100%;
            width: 100%;
            padding: 10px;
          }
          input::placeholder {
            color: #9aa1af;
            font-size: 16px;
          }
          select {
            background: none;
            border: none;
            outline: none;
            height: 100%;
            width: 100%;
            padding: 10px;
          }
        }

        button {
          width: 100%;
          height: 48px;
          display: block;
          border-radius: 24px;
          font-size: 16px;
          font-weight: bold;
          border: none;

          &._yes {
            color: #fff;
            background-color: #16284d;
            margin-bottom: 20px;
          }
        }
      }

      ._hold-contentProduct {
        width: 100%;

        h2 {
          font-size: 18px;
          color: #16284d;
          font-weight: bold;
        }

        .search-bar {
          width: 100%;
          height: 48px;
          background: #f6f7f8;
          border-radius: 12px;
          margin: 10px auto;
          border: 0.3px dashed #16284d;
          input {
            background: none;
            border: none;
            outline: none;
            height: 100%;
            width: 100%;
            padding: 10px;
          }
          input::placeholder {
            color: #9aa1af;
            font-size: 16px;
          }
        }

        button {
          width: 100%;
          height: 48px;
          display: block;
          border-radius: 24px;
          font-size: 16px;
          font-weight: bold;
          border: none;

          &._yes {
            color: #fff;
            background-color: #16284d;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.uploader_ {
  border-radius: 10px;
}

.colorsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  h6 {
    color: #16284d;
    font-weight: bold;
  }
}

.colorVariants {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  justify-content: space-between;

  & > div {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #16284d;
    cursor: pointer;
  }
}

.sizeVariants_ {
  .holdSizeType {
    width: 50%;
    background-color: #dcdee344;
    padding: 10px;
    border-radius: 5px;
    color: #16284d;
    font-weight: bold;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
  }
}

.editCtgyImGBzx {
  width: 50%;
  height: 150px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #dbdde0;
  margin: 10px auto;

  img {
    width: 100%;
    height: 150px;
  }
}

.IProductImages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  justify-content: space-between;
  margin: 20px 0;

  .oNiewq {
    width: 100%;
    height: 200px;
    border-radius: 30px;
    overflow: hidden;
    border: 1px solid #9aa1af;
    cursor: pointer;

    img {
      width: 100%;
      height: 200px;
      border-radius: 30px;
      object-fit: fill;
    }
  }
}

.oiniut {
  &:disabled {
    background-color: #b0b5be !important;
    cursor: not-allowed;
  }
}
