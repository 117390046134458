.storeBox__ {
  margin-top: 40px;

  ._hold-stores {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

._hold-management-tabs {
  margin-top: 30px;

  .wrap-tabs {
    display: flex;
    justify-content: space-between;
    ._hold-search-sort {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ._sort-management {
        position: relative;
      }
    }
    ._tabs {
      .nav-item {
        margin-right: 20px;
        .nav-link {
          width: auto;
          height: 40px;
          background: #e8ecf0;
          border-radius: 20px;
          font-size: 16px;
          color: #9aa1af;
          &.active {
            font-weight: bold;
            color: #fff;
            background-color: #3ed0a3;
          }
        }
      }
    }
  }
}
