._select-store {
  width: 300px;
  height: 48px;
  .css-2b097c-container,
  .css-yk16xz-control {
    background-color: #3e4452;
    height: 48px;
    width: 100%;
    color: #fff;
    border-radius: 10px;
    border: none;
    outline: none;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-26l3qy-menu {
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 0px 0px 15px 15px;
    color: #16284d;
  }

  .css-1wa3eu0-placeholder,
  .css-tlfecz-indicatorContainer {
    color: #fff;
  }

  .css-g1d714-ValueContainer {
    background-color: #3e4452;
    height: 48px;
    border-radius: 10px;
  }

  .css-1uccc91-singleValue {
    color: #fff;
  }
  .css-1pahdxg-control {
    background-color: #3e4452 !important;
    border: none;
    box-shadow: none;
    border-radius: 10px;
    colro: #fff;
  }
}
