._your-platforms {
  width: 100%;
  border: 1px solid #dcdee3;
  border-radius: 28px;
  padding: 24px;

  h2 {
    font-size: 16px;
    color: #16284d;
    font-weight: bold;
    margin-bottom: 20px;
  }

  ._on-building {
    width: 250px;
    height: 180px;
    background: #f6f7f8;
    border-radius: 13px;
    padding: 24px;

    ._hold-btn {
      display: flex;
      justify-content: flex-end;
    }

    ._text {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      span {
        font-size: 15px;
        color: #9aa1af;
        font-weight: normal;
      }
      h3 {
        font-size: 21px;
        color: #16284d;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  ._on-building-hold {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
    &:hover {
      transform: scale(0.98);
    }
  }
}
