:root {
  /* All Colors will be here */
  --about-top-bg: #e2f8f1;
  --buttonBorderRadiusMd: 20px;
  --blue: #16284d;
  --grey: #777c85;
  --backgroundColor: #f6f7f8;
  --about-mid: #e5e5e5;
  --greySearch: #e8ecf0;
  --white: #ffffff;
  --green: #3ed0a3;
  --shadedblack: #3e4452;
  --fontSearch: #9aa1af;
  --backgroundColorSecond: #2b5045;
  --processBackGroundColor: #3ed0a3;
  --black: #000000;
  /* All colors end */

  /* All Font Styling will be here */
  --fontFamily: Montserrat;
  --fontFamilysecond: Arial;
  --fontsize: 1.875rem;
  --fontsizeMedium: 0.9375rem;
  --fontsizeML: 24px;
  --fontsizeMediumMD: 1.3vw;
  --buttonFontSize: 2vw;
  --fontweightMedium: 500;
  --fontweight: 800;
  --fontweightnine: 900;
  --fontWeightBold: bold;
  --fontweightsecond: normal;
  --letterSpacing: 0.012vw;
  --letterSpacingxs: 0.01vw;
  --line-height: 4.1vw;
  --line-heightSmall: 1;

  /* All Font Styling end */
  --buttonBorderRadius: 8px;
  --faq-accordion-border-radius: 35px;
  --letterSpacingMD: 0.021vw;
  --line-height: 2.9375rem;
  --line-height-medium: 3vw;
  --line-heightSmall-medium: 2.5vw;
  /* All Font Styling end */

  --cardsButtonBGColor: rgba(62, 208, 163, 1);
  --cardButtonTextColor: #3ed0a3;
  --cardBorderRadius: 18.5px;
  --buttonBorderRadius: 8px;
  --buttonBorderRadiuslarge: 20px;
}

.footerDesign {
  height: 15px;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.1875px;
  color: rgba(255, 255, 255, 0.603038);
}

.forFloatLeft {
  float: right;
}

.forLeftRight {
  margin: 10px 50px 20px 50px;
}

.coloring {
  /* color: red; */
  margin: auto;
  width: 50%;
  text-align: center;
}

/* .footerDesign {
  background-color: #16284D;
  color: #FFFFFF;
  padding:10px 30px;
} */

.centering {
  margin: 20px 0px;
  text-align: center;
}

.about-card-content {
  max-width: 270px;
}

.home {
  margin-top: 60px;
  list-style: none;
}

.home li {
  width: 100%;
  display: inline;
  padding: 30px;
}

.f_heading {
  color: var(--blue);
  margin-top: 10vh;
  /* margin: '60px 0px 0px 0px' ; */
  text-align: center;
  font-weight: var(--fontweight);
  font-size: 30px;
  line-height: 47px;
  letter-spacing: 0.375px;
}

.faqDiv {
  position: absolute;
  width: 1049px;
  left: 195px;
  background: var(--backgroundColor);
  border-radius: 35px;
}

/* Home page CSS will apply here */

.home-header {
  background-color: var(--backgroundColor);
  /* color: var(--white); */
  background-image: url("./landingPage/assests/images/Header_backimage.svg");
  background-size: "auto";
  background-position: left;
  background-repeat: no-repeat;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  /* white-space: nowrap; */
}

.header-container {
  padding: 20px 20px 0px 20px;
}

.home-headercontainer {
  padding: 190px 20px;
  align-items: center;
}

.left-side-content {
  text-align: right;
}

.left-header-image {
  max-width: 100%;
}

.headerMainHeading {
  font-weight: var(--fontweight);
  font-size: var(--fontsize);
  line-height: var(--line-height);
  letter-spacing: var(--letterSpacing);

  color: var(--blue);
}

.MuiGrid-root.headersmallHeading {
  /* font-family: var(--fontFamily); */
  font-weight: var(--fontweightsecond);
  font-size: var(--fontsizeMedium);
  line-height: var(--line-heightSmall);
  letter-spacing: var(--letterSpacingxs);
  color: var(--grey);
  margin-top: 15px;
}

.MuiGrid-root.Inputfields {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 20px;
  margin-top: 35px;
}

.MuiButton-root.StartNow {
  background-color: var(--blue);
  color: var(--white);
  border-radius: var(--buttonBorderRadius);
  text-transform: var(--texttransform);
  line-height: var(--line-heightSmall);
  font-weight: var(--fontWeightBold);
  padding: 13px;
  margin-left: 22px;
}

.MuiButton-root.StartNowNew {
  background-color: var(--blue);
  color: var(--white);
  border-radius: var(--buttonBorderRadius);
  text-transform: var(--texttransform);
  line-height: var(--line-heightSmall);
  font-weight: var(--fontWeightBold);
  padding: 13px;
}

.MuiButton-root.StartNowNew:hover {
  background-color: var(--white);
  /* width:10rem; */
}

.MuiButton-root.StartNow:hover {
  background-color: var(--blue);
  /* width:10rem; */
}

.home-medium-content {
  padding: 80px;
  /* align-items: center; */
} 

.goal-heading {
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.3px;
  color: #16284d;
  padding-bottom: 64px;
  /* margin-top: -100px; */
}

.home-medium-content-text {
  align-items: center;
  text-align: center;
}

.MuiTypography-root.home-medium-content-h1 {
  font-family: var(--fontFamily);
  font-weight: var(--fontweight);
  font-size: var(--fontsizeML);
  line-height: var(--line-height-medium);
  /* or 167% */

  letter-spacing: 0.3px;

  color: var(--blue);
}

.MuiTypography-root.home-medium-content-h2 {
  font-family: var(--fontFamily);
  font-weight: var(--fontweightsecond);
  font-size: var(--fontsizeMediumMD);
  line-height: var(--line-heightSmall);
  /* identical to box height, or 153% */

  letter-spacing: var(--letterSpacingxs);

  color: var(--grey);
}

.home-item-carousel {
  /* align-items: center; */
  text-align: center;
  background-color: var(--backgroundColor);
  padding: 80px;
  width: 100vw;
}

.home-carousel-goal-heading {
  font-style: var(--fontStyle);
  font-weight: var(--fontweight);
  font-size: var(--fontsizeML);
  line-height: var(--line-heightSmall-medium);
  /* or 150% */
  letter-spacing: 0.3px;
  /* margin-top: 100px; */
  padding-top: 95px !important;

  color: #16284d;
}

.home-carousel-sub-heading {
  color: var(--shadedblack);
  margin-top: 16px;
}

.MuiButton-root.header-Carousel-availableButton {
  border-radius: var(--buttonBorderRadiuslarge);
  /* margin-top: 40px; */
  margin-top: -160px;

}



.home-selling-product {
  background-color: var(--processBackGroundColor);
  padding: 60px 0px;
  color: var(--white);
  /* display: "flex"; */

  /* j: "column"; */
  /* flex-direction: "column"; */
  text-align: center;
}

.home-selling-product-card {
  justify-content: center;
  gap: 60px;
}

.home-card-container-one {
  background-color: #2bc092;
  border-radius: 24px;
  font-weight: var(--fontweight);
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.25px;
  height: 194px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--white);
  background-image: url(./landingPage/assests/images/pricing.svg);
  background-size: "auto";
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.home-card-container-two {
  background-image: url(./landingPage/assests/images/explore-theme.svg);
}

.home-card-container-three {
  background-image: url(./landingPage/assests/images/light-bulb.svg);
}

.home-sellingheading {
  font-weight: var(--fontweight);
  font-size: 1.5rem;
  color: var(--white);
  margin-top: 40px;
}

.MuiButton-root.home-StartNowBottom {
  background-color: var(--white);
  color: var(--blue);
  border-radius: var(--buttonBorderRadiuslarge);
  margin-top: 20px;
}

/* header page css will end here */

/* faq_pages css ______________*/
button.MuiButton-root.searchButton {
  text-transform: initial;
  margin-left: 2vh;
  height: 40px;
  width: 10%;
  background-color: var(--blue);
  color: var(--white);
  border-radius: var(--buttonBorderRadius);
}

.MuiTextField-root.searchField {
  height: 40px;
  width: 80%;

}

.text-area {
  background: #FFFFFF;
  border: 1px solid #DCDEE3;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-top: 20px;
  letter-spacing: 0.175px;
  width: 616px;
  color: #9AA1AF;
}

button.MuiButton-root.buttonAfterSearch {
  text-transform: initial;
  font-size: 16px;
  margin: 0 2vh;
  background-color: var(--greySearch);
  color: var(--fontSearch);
  border-radius: var(--buttonBorderRadiusMd);
  margin: 10px;
}

/* button.MuiButton-root.buttonAfterSearch:first-child {
  text-transform: initial;
  font-size: 16px;
  margin: 0 2vh;
  background-color: var(--green);
  color: var(--white);
  font-weight: var(--fontWeightBold);
  border-radius: var(--buttonBorderRadiusMd);
  margin: 10px;
} */

button.MuiButton-root.kk {
  text-transform: initial;
  font-size: 16px;
  margin: 0 2vh;
  background-color: var(--green);
  color: var(--white);
  font-weight: var(--fontWeightBold);
  border-radius: var(--buttonBorderRadiusMd);
  margin: 10px;
}


.faqForm {
  text-align: center;
  /* background-color: #16284D;   */
  margin: 7vw;
}

.faqEnquiry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 100px 0px;
  /* margin-top: 2em; */
  /* text-align: center; */
}

.MuiTextField-root.enquiryField {
  /* text-align: center; */
  width: 50%;
  margin: 10px 0px;
}

button.MuiButton-root.enquryFieldButton {
  width: 90%;
  margin: 10px 0px;
  color: var(--white);
  background: var(--fontSearch);
  border-radius: var(--buttonBorderRadiusMd);
}

button.MuiButton-root.enquryFieldButtonColor {
  width: 90%;
  margin: 10px 0px;
  color: var(--white);
  background: #3ED0A3 !important;
  border-radius: var(--buttonBorderRadiusMd);
}


/*  */

.MuiTypography-root.enquiryText {
  color: var(--blue);
  font-weight: var(--fontweight);
  font-size: 24px;
  line-height: var(--line-height);
}

.MuiAccordion-root.accord_radius {
  background-color: var(--backgroundColor);
  color: var(--blue);
  padding: 20px;
  margin: 0 14vh;
}

.MuiAccordion-root.accord_radius:first-child {
  border-radius: var(--faq-accordion-border-radius) var(--faq-accordion-border-radius) 0px 0px;
  background-color: var(--backgroundColor);
  color: var(--blue);
  margin: 0 14vh;
}

.MuiAccordion-root.accord_radius:last-child {
  border-radius: 0px 0px var(--faq-accordion-border-radius) var(--faq-accordion-border-radius);
  background-color: var(--backgroundColor);
  color: var(--blue);
  margin: 0 14vh;
}

.MuiAccordion-root.accord_radius_new {
  border: 1px solid black;
  border-radius: var(--buttonBorderRadius);
  color: var(--blue);
  margin: 0 14vh;
}

.MuiTypography-root.accord_desc {
  font-family: var(--fontFamilysecond);
  color: var(--grey);
  font-size: 1em;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.MuiTypography-root.accordian-title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.225px;
  color: var(--blue);
}

/* -----------------------------------------------footer styling ------------------------------------------------- */
.footer_container {
  background-color: var(--blue);
  /* height: 527px; */
  padding: 40px 0px 20px;
}

.margin-one {
  margin: 2rem 0px;
}

.footer_ul {
  list-style: none;
  /* display: inline-block; */
}

.footer_li {
  display: inline-block;
  margin: 0px 3vw;
  font-weight: var(--fontweightMedium);
}

.footer_link {
  text-decoration: none;
  color: var(--white);
}

.float-right {
  float: right;
}

.horizonLining {
  margin: 0 20px;
  color: white;
}

.privacy_terms {
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.1875px;
  color: rgba(255, 255, 255, 0.603038);
}

.hr_line {
  mix-blend-mode: normal;
  background-color: #FFFFFF;
  opacity: 0.2;
}

button.MuiButton-root.footer_sign_button {
  margin-left: 2vh;
  height: 40px;
  background-color: var(--green);
  color: var(--white);
  border-radius: var(--buttonBorderRadius);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: none;
  padding: 20px;
}

.MuiTextField-root.footer_sign_textfield {
  height: 40px;
  width: 263px;
  background-color: var(--white);
  border-radius: var(--buttonBorderRadius);
  margin-bottom: 30px;
}

/* ---------------------------------------------------faq design end------------------------------------------------- */

/* -------------------------------------------about page design--------------------------------------------------------- */

.about-heading-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-heading-text {
  color: var(--blue);
  width: 883px;
  font-weight: var(--fontweight);
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.375px;
  text-align: center;
}

.aboutus-section-2 {
  padding: 120px 0px;
}

.MuiContainer-root.MuiContainer-maxWidthLg.content-size.css-1oqqzyl-MuiContainer-root {
  /* text-align: center;
  padding-left: 80px;
  margin-left: 80px; */
  padding: 0px 100px 120px;
  /* border:1px solid red;
   margin: 0 auto;
   width: 60%; */
}

/* 
.about_heading {
  color: var(--blue);
  margin-top: 40px;
  width: 883px;
  text-align: center;
  font-weight: var(--fontweight);
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.375px;
} */


.about_heading-card {
  color: var(--blue);
  width: 640px;
  text-align: center;
  font-weight: var(--fontweight);
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.375px;
}

.MuiTypography-root.lizard {
  font-family: var(--fontFamily);
  font-size: 22px;
  line-height: 22px;
  font-weight: var(--fontWeightBold);
  color: var(--blue);
}

.MuiTypography-root.lizard-desc {
  font-family: var(--fontFamily);
  color: var(--grey);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: #777C85;
}

.about-top-heading {
  max-width: 80vw;
  color: var(--blue);
  font-weight: var(--fontweight);
  text-align: center;
  font-size: 30px;
  line-height: 47px;
  letter-spacing: 0.375px;
}

.about-top-heading-para {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  margin-top: 15px;

  /* text-align: center; */
  letter-spacing: 0.2px;
  width: 930px;
  color: #777c85;
}

.card-heading {
  color: var(--blue);
  font-weight: var(--fontweight);
  font-size: 32px;
  /* width: 433px; */
  line-height: 45px;
  letter-spacing: 0.375px;
  margin-top: 50px;
}

.card-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  /* width: 372px; */
  line-height: 26px;
  letter-spacing: 0.2px;
  color: #777c85;
}

.about-top {
  padding: "70px 90px 0px";
  width: 100%;
  background-color: var(--about-top-bg);
}


.center_anything_about {
  width: 100%;
  /* margin: 30px 0px; */
  text-align: center;
}

.bg-img-about {
  background-image: url("./landingPage/assests/images/about_top.png");
  background-repeat: no-repeat;
  background-position: center;
  /* height: 90vh; */
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.theme-box {
  /* margin-top: "140px"; */
  background-color: var(--backgroundColor);
}

.img-div {
  margin-top: 140px;
  /* width: 80vw;
  margin: auto; */
}

@media only screen and (min-width: 500px) {
  .img-div {
    width: 70rem;
    margin: auto;
  }
}

.element_class-one {
  width: 100%;
  text-align: start;
  /* width: 433px; */
}

/* 
.element-sub-class-two{
  width: 433px;
} */

.element_class-two {
  width: 100%;
  /* text-align: start; */
  text-align: end;
  /* width: 433px; */
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-direction: row-reverse; */
}

/* 
.element_class:nth-child(2) {
  text-align: end;
}
.element_class:nth-child(4) {
  margin-top: -3rem;
  text-align: end;
} */

.theme-card {
  gap: 2rem;
  min-height: 100%;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


/* -------------------------------------------about page design end--------------------------------------------------------- */

.MuiTypography-root.form-text {
  font-family: var(--fontFamily);
  color: var(--blue);
  font-weight: var(--fontweight);
  font-size: 24px;
  line-height: var(--line-height);
}

.MuiTextField-root.text-field {
  width: 23vw;
  /* margin: 10px 0px; */
  font-family: var(--fontFamily);
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  letter-spacing: 0.175px;

  color: #9AA1AF;
  background: #FFFFFF;
  /* border: 1px solid #DCDEE3; */
  box-sizing: border-box;
  border-radius: 8px;
}

button.MuiButton-root.button-business {
  width: 10vw;
  height: 40px;
  /* margin-top: 50px; */
  margin-left: 25px;
  /* margin: 10px 5px; */
  font-family: var(--fontFamily);
  text-transform: initial;
  color: var(--white);
  background: var(--green);
  border-radius: var(--buttonBorderRadius);
}

.pricing-heading {
  margin-top: 130px;
  color: var(--blue);
  font-weight: var(--fontweight);
  text-align: center;
  font-size: 30px;
  line-height: 47px;
  letter-spacing: 0.375px;
}

.pricing-heading-para {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #777c85;
}

.pricing-accordian-header-div {
  background: var(--greySearch);
  width: 80%;
  margin: auto;
  padding: 10px;
  border-radius: 14px;
}

div#1.column-design-expansion-two {
  margin-top: -20px !important;

}

div#2.column-design-expansion-two {
  margin-top: -40 !important;

}

div#3.column-design-expansion-two {
  margin-top: -60px !important;

}

div#4.column-design-expansion-two {
  margin-top: -80px !important;

}

.column-div {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  gap: 20px;
  /* background-color: red; */
}

.column-design {
  /* margin: 30px 0px; */
  background-color: var(--backgroundColor);
  border-radius: 20px;
  width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-top: 36px;
  /* justify-items: center;
  text-align: center; */
}

.column-header {
  font-style: normal;
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  line-height: 18px;
  color: var(--blue);
}

.header-div {
  background: var(--greySearch);
  width: 184px;
  height: 55px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  /* padding: 10px; */
  /* height: 113px; */
  padding-top: 11.5px;
  border-radius: 14px;
  /* margin-top: -16px; */
  /* border:1px solid red; */

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  /* line-height: 18px; */

}

.header-div.header-div-margin-top {
  margin-top: -19px;
  position: relative;
  top: 10px;
}

.center {
  text-align: center;
}

.header-div-first {
  width: 80%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* margin: auto; */
  /* padding-left: 18px; */
  /* border-radius: 14px; */


  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */
  margin: auto;
  text-align: center;
  letter-spacing: 0.225px;

  color: #16284D;

}

.header-div .header-div-text {
  font-family: var(--fontFamily);
  letter-spacing: 0.275px;
  /* font-weight: 700; */
  color: var(--blue);
  /* font-size: 22px; */
  /* line-height: 22px; */
}

.header-div-desc {
  font-size: 15px;
  line-height: 15px;
  color: var(--shadedblack);
}

.header-div-desc2 {
  font-size: 15px;
  line-height: 15px;
  color: var(--greySearch);
}



.header-div-para {
  border-bottom: 2px dashed #dcdee3;
  border-radius: 0px;
  color: var(--shadedblack);
  height: 69px;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 8px; */
  padding-top: 17px;
  padding-bottom: -4px;
  letter-spacing: 0.1875px;
  background-color: var(--backgroundColor);
  /* border:1px solid red; */
}

.header-div-para:last-child {
  border-bottom: none;
  background-color: var(--backgroundColor);
}

.bottom-buttons-div {
  height: 113px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

button.MuiButton-root.column-button {
  width: 240px;
  background-color: var(--green);
  margin: 5px 0px;
  border-radius: 20px;
  color: var(--white);
  text-transform: initial;
}

.header-div-package {
  background: var(--greySearch);
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* padding: 10px; */
  height: 113px;
  border-radius: 14px;
}

.container-column-div-expansion {
  display: flex;
  justify-content: center;
}

.column-design-expansion-two {
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 20%;
  border-left: 2px solid #dcdee3;
}


div.column-design-expansion-two#mobile {
  border-left: 2px solid white;
}

.column-design-expansion {
  margin: 30px 0px;
  width: 250px;
  border-left: 2px solid #dcdee3;

  /* justify-content: center; */
  /* align-items: center; */
  /* justify-items: center; */
  /* text-align: center; */
}

.column-design-expansion:first-child {
  margin: 30px 0px;
  width: 20%;
  border-left: none;
  /* justify-items: center; */
  /* text-align: center; */
}


.column-div-expansion {
  display: flex;
  /* flex-wrap: wrap; */
  /* text-align: center; */
  /* justify-content: center; */
  border-radius: 20px;
  margin: 0px 0px 100px;
  /* margin: 0px 0px 100px 45px; */
  background-color: var(--backgroundColor);
  width: 80%;
}

.theme_classes {
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.2px;
  color: #16284d;
  padding: 5px 2px;
}

.theme_heading_alignment {
  margin-top: 46px;
  position: relative;
  top: -11px;
}

.theme_classes_first_alignment {
  border-bottom: 1px dotted grey;
  padding-bottom: 12px;
  margin-top: -4px;
}

.theme_classes_alignment {
  border-bottom: 1px dotted grey;
  padding-bottom: 10px;
  margin-top: -4px;
}

.theme_classes-theme {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  margin-top: 30px;
  letter-spacing: 0.2px;
  color: #16284D;
}

.theme-rows {
  margin-top: 1px;
  padding: 7px 2px;
  border-bottom: 1px dotted grey;
  width: 100%;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 10px;
}

.theme-rows-mobile {
  margin-top: 1px;
  padding: 7px 2px;
  border-bottom: 1px dotted transparent;
  width: 100%;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 10px;
}


.theme-rows-responsive {
  margin-top: 1px;
  padding: 7px 2px;
  /* border-bottom: 1px dotted grey; */
  width: 100%;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 10px;
}

button.MuiButton-root.for-detailed-button {
  font-weight: var(--fontWeightBold);
  font-size: 16px;
  width: 280px;
  font-family: 'Montserrat';
  line-height: 19.5px;
  letter-spacing: 0.2px;
  color: var(--blue);
  text-transform: initial;
  /* background: var(--white); */
  border: 1px solid var(--fontSearch);
  border-radius: 20px;
  margin: 30px 0px;
}

/* Learn More */
.learn-more-card {
  width: 450px;
}

.learnmore-cardimages {
  border-radius: 14px;
  box-shadow: none;
}

.Learnmore-Cards-Button {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.learn-more-cardcontainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* card Grid css */

.learnmore-card-content {
  margin-top: 30px;
  justify-content: left;
}

/* card Grid css end */

.learnmore-headings {
  text-align: center;
  margin-top: 90px;
}

.learnmore-theme-card {
  gap: 2rem;
  margin-top: 80px;
  /* min-height: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main-heading {
  font-weight: 800;
  font-size: 30px;
  line-height: 47px;
  letter-spacing: 0.375px;
  color: #16284d;
}

.submain-heading {
  font-weight: var(--fontweightsecond);
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: var(--grey);
}

.MuiButton-root .cardButtons {
  background-color: var(--cardsButtonBGColor);
  color: var(--cardButtonTextColor);
  border-radius: var(--cardBorderRadius);
}

.learnMore-sellingproduct {
  margin-top: 60px;
  padding: 50px 0px;
  background-color: var(--backgroundColor);
  text-align: center;
}

.learnMore-secondHeadingText {
  font-family: var(--fontFamilysecond);
  font-weight: var(--fontweightnine);
  font-size: var(--fontsize);
  line-height: 24px;
  letter-spacing: 0.3px;
  color: var(--black);
}

/* .learnmore-header {
  padding: 4em 10rem;
} */

.learnmore-headings {
  text-align: center;
}

.main-heading {
  font-weight: 800;
  font-size: 30px;
  line-height: 47px;
  letter-spacing: 0.375px;
  color: #16284d;
}

.submain-heading {
  font-weight: var(--fontweightsecond);
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: var(--grey);
}

.MuiButton-root .cardButtons {
  background-color: var(--cardsButtonBGColor);
  color: var(--cardButtonTextColor);
  border-radius: var(--cardBorderRadius);
}

.learnMore-accord_radius {
  background-color: var(--backgroundColor);
  color: var(--blue);
  padding: 20px;
  margin: 0 14vh;
}

.MuiAccordion-root.learn-more-Accordion-heading {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: var(--blue);
}

.MuiAccordion-root.learnMore-accord_radius:first-child {
  border-radius: var(--faq-accordion-border-radius) var(--faq-accordion-border-radius) 0px 0px;
  background-color: var(--white);
  color: var(--blue);
  margin: 0 14vh;
}

.MuiAccordion-root.learnMore-accord_radius:last-child {
  border-radius: 0px 0px var(--faq-accordion-border-radius) var(--faq-accordion-border-radius);
  background-color: var(--white);
  color: var(--blue);
  margin: 0 14vh;
}

.card-text-button {
  margin-top: 20px;
}

.MuiTypography-root.card-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.275px;
  color: #16284d;
}

.MuiTypography-root.card-sub-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
  letter-spacing: 0.2px;
  color: #777c85;
}

.MuiButton-root.Card-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.175px;
  color: #3ed0a3;
  background-color: #e2f8f1;
  mix-blend-mode: normal;
  text-transform: initial;
  border-radius: 18.5px;
  padding: 10px 15px;
}

.MuiButton-root.Card-button:hover {
  background-color: #e2f8f1;
}

/* --------------------------service class---------------------------- */
.service-card-content {
  max-width: 100%;
}

.MuiTypography-root.service-text-one {
  font-size: 32px;
  line-height: 45px;
  margin-left: 35px;
  color: var(--blue);
  font-weight: var(--fontWeightBold);
}

.MuiTypography-root.service-desc-one {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  width: 40vw;
  margin-left: 35px;
  line-height: 26px;
  letter-spacing: 0.2125px;
  color: #777c85;
}

.service-div-content {
  min-height: inherit;
  margin-top: auto;
  text-align: start;
  margin-bottom: 20px;
  margin-left: 30px;
}

.MuiTypography-root.service-text-two {
  font-size: 32px;
  line-height: 45px;
  margin-right: 30px;
  color: var(--blue);
  font-weight: var(--fontWeightBold);
}

.MuiTypography-root.service-desc-two {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  width: 40vw;
  margin-right: 35px;
  line-height: 26px;
  letter-spacing: 0.2125px;
  color: #777c85;
}

.service-type-one {
  display: flex;
  flex-wrap: wrap;
}

.service-div-content-two {
  min-height: inherit;
  margin-top: auto;
  text-align: end;
  margin-bottom: 20px;
  margin-right: 30px;
}

.service-type-two {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.MuiCard-root.service-home-card {
  border-radius: 24px;
}

.MuiPaper-root.carousel-paper {
  background-color: var(--backgroundColor);
  max-width: 100%;

}

img {
  width: '100%'
}

.carousel-center {
  background-color: var(--backgroundColor);
  text-align: center;
  margin-top: 100px;
  /* padding: "100px"; */
}

.carousel-home-div {
  max-width: 100%;
  /* width: 914.5px; */
  margin-top: 50px;
  height: 546px;
  box-shadow: none;
}

.content-size {
  /* text-align: center;
  padding-left: 80px;
  margin-left: 80px; */
  padding: 0px 100px 120px;
  /* border:1px solid red;
   margin: 0 auto;
   width: 60%; */
}


.content-resizing1:nth-child(even) {

  margin-top: -150px;
  flex-direction: row-reverse;
  text-align: end;
}



/* Mobile responsive css */



@media (max-width: 600px) {


  /* Home Page */
  .margin-one.social-icon {
    width: 40px;
    height: 40px;
  }

  .headerMainHeading {
    line-height: 30px;
    padding: 20px 0px;
  }

  .carousel-center {
    margin-top: -20px;
    padding-top: -15px;
  }

  .home-medium-content-h1.line-height-increase {
    line-height: 18px;
  }

  .goal-heading {
    margin-top: -70px;
  }

  .carousel-home-div {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  img.navButton {
    width: 30px;
  }

  img.navButton1 {
    width: 30px;
  }

  .MuiButton-root.header-Carousel-availableButton {
    margin-top: -690px;
  }

  .css-1abc02a {
    margin-top: -120px;
  }

  .css-hn784z {
    margin-top: -120px;
    margin-left: auto;
    margin-right: auto;
  }


  .carousel-center {
    height: 560px;
    width: 100%;
  }

  .center img {
    margin: auto;
  }

  /* About Us page css */

  .about-top-heading-para {
    width: 340px;
  }

  .about-heading-text {
    width: 340px;
  }

  .content-size {
    width: 340px;
    /* border: 1px solid green; */
    /* margin-left:-20px; */
    /* padding:0px 100px 120px; */
  }

  .Imagesize {
    margin-left: -30px;
    margin-top: 20px;
  }

  .card-heading {
    width: 240px;
    color: var(--blue);
    font-weight: var(--fontweight);
    font-size: 32px;
    /* width: 433px; */
    line-height: 45px;
    letter-spacing: 0.375px;
    margin-top: 50px;
    text-align: center;
    margin-left: -30px;
  }

  .card-desc {
    width: 200px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    /* width: 372px; */
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #777c85;
    margin-left: -16px;
  }

  /* .content-resizing{
    margin-top:0;
    flex-direction: 0;
    text-align: center;
    margin:auto;
    border:10px solid black;
   
  }  */
  .content-resizing1:nth-child(even) {

    margin-top: 0px;
    flex-direction: row;
    text-align: left;
  }

  /* .about_heading-card{
    width: 350px;
  } */
  .header-div-first-mobile {
    width: 80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    margin: auto;
    text-align: center;
    letter-spacing: 0.225px;
    color: #16284D;
    margin-top: 20px;
    margin-left: -10px;
    width: 180px;
  }

  .container-mobile {
    margin-right: 4px;
    border: 1px solid transparent;
  }

  .theme_classes-mobile {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.2px;
    color: #16284d;
    padding: 5px 2px;
    width: 120px;
  }

  .theme_heading_alignment-mobile {
    margin-top: 46px;
    position: relative;
    top: -11px;
    margin-left: -20px;
  }

  .theme-number-mobile {
    margin-left: 125px;
    border: 1px solid transparent;
    width: 130px;
    margin-top: -5px;
  }

  .rating-item-mobile {
    width: 90px;
    margin-top: 26px;
    margin-left: 16px;
  }

  .image-styling-mobile {
    margin-left: -60px;
    padding-top: 21px;
    margin-top: 14px;
    border: 1px solid transparent;
    border-bottom: none;
  }

  .container-column-div-expansion.hide-in-mobile {
    display: none;
  }

  .image-width {
    width: 80px;
  }

  .modal-width {
    width: 200px;

  }

  .box-width {

    width: 300px !important;
    height: 300px;
    margin-left: 75px;

  }

  .image-modal-size {

    width: 30px;
    height: 30px;
    margin-top: -10px;
    position: absolute;

  }

  .card-component {

    width: 280px !important;
    margin-left: -25px;
    margin-top: 10px;
  }

  .box-alignment-mobile {
    width: 350px;
    margin-left: -30px;
  }

  .accord_radius {
    width: 300px;
    margin-left: 14px !important;
  }

  .enquryFieldButton {
    width: 200px !important;
    margin-left: -8px !important;
  }

  .heading-mobile-responsive { 
    color: var(--blue);
    width: 640px;
    text-align: center;
    font-weight: var(--fontweight);
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.375px;
    width: 300px;
  }

  .mobile-responsive-form-text{
    font-family: var(--fontFamily);
    color: var(--blue);
    font-weight: var(--fontweight);
    font-size: 24px;
    line-height: var(--line-height);
    width: 358px;
    padding:0px 40px;
    text-align: center;
  }

  .input-box-mobile-responsive{
    /* width: 23vw; */
    /* margin: 10px 0px; */
    font-family: var(--fontFamily);
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */
  
    letter-spacing: 0.175px;
  
    color: #9AA1AF;
    background: #FFFFFF;
    /* border: 1px solid #DCDEE3; */
    box-sizing: border-box;
    border-radius: 8px;
    width:250px;
  }
  .button-business{
    /* width: 10vw; */
    width:130px !important;
  height: 40px;
  /* margin-top: 50px; */
  margin-left: 25px;
  /* margin: 10px 5px; */
  font-family: var(--fontFamily);
  text-transform: initial;
  color: var(--white);
  background: var(--green);
  border-radius: var(--buttonBorderRadius);
  margin-top:16px !important;
  margin-left:-2px !important;
  }

  /* .mobile-responsive-button{
    width:100px;
    height: 40px;
    /* margin-top: 50px; */
    /* margin-left: 25px; */
    /* margin: 10px 5px; */
    /* font-family: var(--fontFamily);
    text-transform: initial;
    color: var(--white);
    background: var(--green);
    border-radius: var(--buttonBorderRadius);
    border:1px solid red; */
  /* }  */
}



.MuiPaper-root.carousel-paper {
  box-shadow: none;
}



/* --------------------------service class---------------------------- */

.MuiDrawer-root.drawer-class {
  height: auto;
}

/* Learn More */

/* media query */

@media (max-width: 575.98px) {
  .learnmore-header {
    padding: 0em;
  }

  .left-header-image img {
    width: 100%;
    height: auto;
  }

  .home-medium-content {
    padding: 20px;
  }

  .home-selling-product {
    padding: 20px;
  }

  .home-headercontainer {
    padding: 20px 10px;
    align-items: center;
    text-align: center;
  }

  .left-side-content {
    text-align: center;
  }

  .MuiGrid-root.Inputfields {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 20px;
  }

  .MuiTypography-root.home-medium-content-h1 {
    font-size: 15px;
    letter-spacing: 0.3px;
  }

  .MuiTypography-root.home-medium-content-h2 {
    font-size: 15px;
    margin: 10px;
  }

  .service-type-one {
    justify-content: center;
    flex-wrap: wrap;
  }

  .service-div-content {
    text-align: center;
  }

  .service-div-content-two {
    min-height: inherit;
    margin-top: auto;
    text-align: center;
    margin-bottom: 0px;
  }

  .service-type-two {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .MuiTypography-root.service-desc-two {
    font-size: 25px;
    line-height: 30px;
    width: auto;
    margin-right: 0px;
  }

  .MuiTypography-root.service-text-two {
    margin-right: 0px;
    margin-top: 10px;
  }

  .MuiCard-root.service-home-card {
    border-radius: 24px;
    width: 100%;
  }

  .MuiTypography-root.service-desc-one {
    font-style: normal;
    font-weight: normal;
    width: auto;
    margin-left: 0px;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.2125px;
    color: #777c85;
  }

  .MuiTypography-root.service-text-one {
    font-size: 32px;
    line-height: 45px;
    margin-left: 0px;
    color: var(--blue);
    font-weight: var(--fontWeightBold);
  }

  .service-type-one {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .home-carousel-goal-heading {
    line-height: 1.43;

  }

  .MuiPaper-root.carousel-paper {
    background-color: var(--backgroundColor);
    max-width: 100%;

  }

  img {
    width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (min-width: 576px) and (max-width: 767.98px) {
  .left-header-image img {
    width: 100%;
    height: auto;
  }

  .home-selling-product {
    padding: 20px;
  }

  .home-medium-content {
    padding: 20px;
  }

  .MuiGrid-root.Inputfields {
    justify-content: center;
    margin-top: 10px;
  }

  .left-side-content {
    text-align: center;
  }

  .headersmallHeading {
    font-weight: var(--fontweightsecond);
    font-size: var(--fontsizeMedium);
    line-height: var(--line-heightSmall);
    letter-spacing: var(--letterSpacingxs);
    color: var(--grey);
  }

  .MuiTypography-root.home-medium-content-h1 {
    font-size: 15px;
  }

  .MuiTypography-root.home-medium-content-h2 {
    font-size: 15px;
    margin: 10px;
  }

  .service-type-one {
    justify-content: center;
    flex-wrap: wrap;
  }

  .service-div-content {
    text-align: center;
  }

  .service-div-content-two {
    min-height: inherit;
    margin-top: auto;
    text-align: center;
    margin-bottom: 0px;
  }

  .service-type-two {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .MuiTypography-root.service-desc-two {
    font-size: 25px;
    line-height: 30px;
    width: auto;
    margin-right: 0px;
  }

  .MuiTypography-root.service-text-two {
    margin-right: 0px;
    margin-top: 10px;
  }

  .MuiCard-root.service-home-card {
    border-radius: 24px;
    width: 100%;
  }

  .MuiTypography-root.service-desc-one {
    font-style: normal;
    font-weight: normal;
    width: auto;
    margin-left: 0px;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.2125px;
    color: #777c85;
  }

  .MuiTypography-root.service-text-one {
    font-size: 32px;
    line-height: 45px;
    margin-left: 0px;
    color: var(--blue);
    font-weight: var(--fontWeightBold);
  }

  .service-type-one {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .learnmore-header {
    padding: 0em;
  }
}


@media (min-width: 767.98px) and (max-width: 1198.98px) {
  .left-header-image img {
    width: 100%;
    height: auto;
  }

  .home-selling-product {
    padding: 20px;
  }

  .left-side-content {
    text-align: center;
  }

  .MuiGrid-root.Inputfields {
    justify-content: center;
    margin-top: 10px;
  }

  .home-headercontainer {
    padding: 20px 10px;
    align-items: center;
    text-align: center;
  }

  .headersmallHeading {
    font-weight: var(--fontweightsecond);
    font-size: var(--fontsizeMedium);
    line-height: var(--line-heightSmall);
    letter-spacing: var(--letterSpacingxs);
    color: var(--grey);
  }

  .MuiTypography-root.home-medium-content-h1 {
    font-family: var(--fontFamily);
    font-weight: var(--fontweight);
    font-size: 20px;
    line-height: var(--line-height-medium);
    letter-spacing: 0.3px;
    color: var(--blue);
  }

  .MuiTypography-root.home-medium-content-h2 {
    font-family: var(--fontFamily);
    font-weight: var(--fontweightsecond);
    font-size: 20px;
    line-height: var(--line-heightSmall);
    margin: 10px;
    letter-spacing: var(--letterSpacingxs);
    color: var(--grey);
  }

  .service-type-one {
    justify-content: center;
    flex-wrap: wrap;
  }

  .service-div-content {
    text-align: center;
  }

  .service-div-content-two {
    min-height: inherit;
    margin-top: auto;
    text-align: center;
    margin-bottom: 0px;
  }

  .service-type-two {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .MuiTypography-root.service-desc-two {
    font-size: 25px;
    line-height: 30px;
    width: auto;
    margin-right: 0px;
  }

  .MuiTypography-root.service-text-two {
    margin-right: 0px;
    margin-top: 10px;
  }

  .MuiCard-root.service-home-card {
    border-radius: 24px;
    width: 100%;
  }

  .MuiTypography-root.service-desc-one {
    font-style: normal;
    font-weight: normal;
    width: auto;
    margin-left: 0px;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.2125px;
    color: #777c85;
  }

  .MuiTypography-root.service-text-one {
    font-size: 32px;
    line-height: 45px;
    margin-left: 0px;
    color: var(--blue);
    font-weight: var(--fontWeightBold);
  }

  .service-type-one {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .learnmore-header {
    padding: 0em;
  }

}

/* media query */

@media screen and (min-width: 600px) {

  /* div.MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root{
    display:none;
  } */
  div.MuiContainer-root.MuiContainer-maxWidthSm.hide-in-desktop.css-cuefkz-MuiContainer-root {
    display: none;
  }
}

.main_app {
  display: grid;
  width: 100%;
  grid-template-columns: 20% 80%;
}



.ModalClose {
  background-color: white !important;
  position: absolute !important;
  right: 0px !important;
  top: -32px !important;
  border-radius: 25px !important;
  padding: 4px !important;
}

.forgotPassStyle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.175px;
  color: #16284D;
  padding-top: 6px;
  cursor: pointer;
}

#emailFormat {
  background-color: '#F6F7F8';
  margin-top: '20px';
  border-radius: '12px';
  height: '48px';
  border: 'none';
}

.column-design-expansion-two {
  padding: 0px;
  padding-top: 23px;
}

.pushBackButton {
  height: 50px;
  padding: 6px;
  position: absolute;
  top: 30px;
  padding: 0 12px;
  padding-top: 6px;
  cursor: pointer;
}