._number-of-visitor {
  width: 190px;
  height: 102px;
  background: #f6f7f8;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 24px;
  ._no {
    font-size: 15px;
    color: #9aa1af;
    font-weight: normal;
    margin-bottom: 0;
  }

  ._number {
    font-size: 26px;
    color: #16284d;
    font-weight: bold;
    margin-bottom: 0;
  }
}
