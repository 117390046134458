._hold-not-found-staff {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 92px;
  ._icon {
    image {
      width: 100%;
    }
  }

  ._hold-text-info {
    margin-top: 20px;
    h3 {
      font-size: 20px;
      color: #16284d;
    }

    ._add-new-user {
      width: 330px;
      max-width: 100%;
      height: 48px;
      font-size: 16px;
      color: #fff;
      background: #3ed0a3;
      border-radius: 24px;
      border: none;
      font-weight: bold;
      margin-top: 20px;
    }
  }
}
