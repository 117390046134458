._hold-account-free {
  margin: 58px 70px;

  ._hold-promo-subs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
  }
}
