@use '../../assets/styles/variables';
@import '../../assets/styles/media-queries';



.platform-card {
    border: 1px solid #DCDEE3;
    border-radius: 28px;
    width: 100%;
    padding: 1.5em 0.5em;
    margin: 1.5em 0;
}
.sub-header-container{
    display: flex;
    // justify-content: space-between;
}
.button-container{
    padding: 8px 10px;
    width: 170px;
    background: #3ED0A3;
    border-radius: 24px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.create-new-button{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
}

@include respond-above(md) {
    .platform-card {
        border: 1px solid #DCDEE3;
        border-radius: 28px;
        width: 533px;
        padding: 1.5em;
        margin: 1.5em 0;
    }
    
}   
@include respond-above(lg) {
    .platform-card {
        width: 49%;
    }
}