._change-plan-modal {
  .modal-content {
    width: 100% !important;
    max-width: 1140px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 14px;

    .modal-body {
      padding: 0;
    }
    .modal-header {
      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #16284d;
      }

      button {
        background-color: transparent;
        border: none;
      }
    }
    ._subs-plan {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ._package {
        width: 291px;
        background: #f6f7f8;
        border-radius: 20px;
        margin-right: 20px;
        text-align: center;
        padding: 20px;
        &:last-child {
          margin-right: 0;
        }

        ._title {
          font-size: 18px;
          color: #16284d;
          font-weight: bold;
          margin-bottom: 25px;
        }

        ._subs-type {
          width: 220px;
          height: 113px;
          margin: 0 auto;
          background: #e8ecf0;
          border-radius: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h4 {
            font-size: 22px;
            color: #16284d;
            font-weight: bold;
          }
        }

        ._benefits {
          padding: 0;
          margin: 0;
          li {
            font-size: 15px;
            color: #3e4452;
            margin: 30px 0 0 0;
            padding-bottom: 30px;
            font-weight: normal;
            border-bottom: 2px dashed #dcdee3;
          }
        }

        ._subs-btn {
          margin-top: 30px;
          button {
            width: 100%;
            height: 40px;
            border-radius: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            border: none;
            &._monthly {
              background-color: #3ed0a3;
              margin-bottom: 16px;
            }

            &._annually {
              background-color: #16284d;
            }
          }
        }

        ._current-plan {
          width: 220px;
          height: 95px;
          background: #dcdee3;
          border-radius: 20px;
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-size: 16px;
            color: #777c85;
            letter-spacing: 0.2px;
            margin: 0 0 0 6px;
            font-weight: bold;
          }
        }
      }
    }
    ._view-detail {
      width: 260px;
      height: 40px;
      background-color: #fff;
      border: 1px solid #9aa1af;
      border-radius: 20px;
      font-size: 16px;
      color: #16284d;
      margin: 50px auto 40px auto;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

._change-btn {
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  width: 107px;
  height: 34px;
  background-color: #16284d;
  border-radius: 20px;
  border: none;
}
