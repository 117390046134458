.createProductItem {
  margin: 58px 50px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actioBoxMerchant {
      display: flex;
      align-items: center;

      .linkBaclDiv {
        background: none;
        border: none;
        outline: none;
        margin-right: 30px;
        cursor: pointer;
      }
      ._title {
        display: flex;
        align-items: center;
        h2 {
          font-size: 34px;
          color: #16284d;
          font-weight: bold;
          margin-right: 16px;
        }
        span {
          font-size: 22px;
          color: #777c85;
          font-weight: normal;
        }
      }
    }

    button {
      width: 190px;
      height: 48px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      color: #fff;
      background-color: #16284d;

      &:disabled {
        background: #dcdee3;
        color: black;
        cursor: not-allowed;
      }
    }
  }

  section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-top: 50px;

    .photos {
      h6 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #16284d;
      }

      .photo-blocks {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;

        .block {
          width: 280px;
          height: 280px;

          background-color: #f6f7f8;
          background-repeat: no-repeat;
          border-radius: 24px;

          &.active {
            background: #3ed0a3;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.not-active {
            background-position: center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            backdrop-filter: brightness(50%);
            cursor: pointer;
            position: relative;
            &:hover {
              filter: brightness(40%);
              .trashItem {
                display: block;
              }
            }
          }

          .trashItem {
            display: none;
            position: absolute;
            top: 30%;
            right: 30%;
          }
        }
      }
    }

    .details_i {
      h6 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #16284d;
      }

      ._hold-contentProduct {
        button {
          width: 190px;
          height: 48px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 24px;
          font-size: 16px;
          font-weight: bold;
          border: none;
          color: #fff;
          background-color: #16284d;
          margin-top: 20px;

          &:disabled {
            background: #dcdee3;
            color: black;
            cursor: not-allowed;
          }
        }

        .sizeVariants_ {
          .holdSizeType {
            width: 50%;
            background-color: #dcdee344;
            padding: 10px;
            border-radius: 5px;
            color: #16284d;
            font-weight: bold;
            margin-bottom: 10px;
            display: grid;
            grid-template-columns: 3fr 1fr;
            gap: 5px;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
