._all-platforms {
  display: flex;
  flex-wrap: wrap;
  margin-top: 44px;
  ._hold-product {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    width: 347px;
    height: auto;
    border: 1px solid #dcdee3;
    border-radius: 28px;
    margin-right: 24px;
    ._info-product {
      cursor: pointer;
      ._hold-btns {
        margin-top: 30px;
        h4 {
          font-size: 26px;
          font-family: 'Montserrat';
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.325px;
          color: #16284D;
        }

        ._btns {
          display: flex;
          margin-top: 15px;
          ._web {
            width: auto;
            height: 31px;
            padding: 0 22px;
            background: #16284d;
            border-radius: 15px;
            border: none;
            color: #fff;
            font-size: 14px;
            margin-right: 20px;
          }

          ._on-building {
            width: auto;
            height: 30px;
            padding: 0 20px;            
            box-sizing: border-box;
            border-radius: 15px;
            font-size: 16px;
            color: #fff;
          }
          .active{
            @extend ._on-building;
            background-color: #28a745 ;
            border: 1px solid #28a745;
          }
          .in-active{
            @extend ._on-building;
            background-color: #dc3545 ;
            border: 1px solid #dc3545;
  
          }
        }
      }
    }
  }
}
