._total-to-date-orders {
  width: 62%;
  background: #f6f7f8;
  border-radius: 28px;
  padding: 30px;
  ._total-date {
    background-color: #e8ecf0;
    border-radius: 14px;
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 40px;
    ._text {
      font-size: 22px;
      color: #9aa1af;
      font-weight: normal;
    }

    ._number {
      font-size: 22px;
      color: #16284d;
      font-weight: bold;
    }
  }

  ._active-order {
    margin-bottom: 35px;
    width: 100%;

    ._active-order {
      ._hold-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 20px;
          color: #7c7c7c;
          font-weight: normal;
        }
        ._number {
          font-size: 20px;
          color: #16284d;
          font-weight: bold;
        }
      }
    }

    ._complete-order {
      margin-bottom: 35px;
      ._hold-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 20px;
          color: #7c7c7c;
          font-weight: normal;
        }
        ._number {
          font-size: 20px;
          color: #16284d;
          font-weight: bold;
        }
      }
    }

    ._cancelled-order {
      margin-bottom: 35px;

      ._hold-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 20px;
          color: #7c7c7c;
          font-weight: normal;
        }
        ._number {
          font-size: 20px;
          color: #16284d;
          font-weight: bold;
        }
      }
    }
  }
}
