.order_page {
  padding: 70px 40px 40px 40px;
  width: 100%;
    ._header {
    display: flex;
    justify-content: space-between;
    ._title {
      font-size: 34px;
      color: #16284d;
      font-weight: bold;
    }
  }
}
