._log-out-modal {
  .modal-content {
    width: 466px;
    height: auto;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 14px;
    position: relative;
    padding: 30px;
    .modal-header {
      border-bottom: none;
      button {
        background-color: transparent;
        border: none;
      }
    }

    .modal-body {
      padding: 0;
    }
    ._close-modal {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    ._content {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 40px;
      h3 {
        font-size: 18px;
        font-weight: bold;
        color: #16284d;
        margin-bottom: 15px;
      }
      p {
        font-size: 15px;
        color: #777c85;
        font-weight: normal;
      }
    }

    ._act {
      display: flex;
      flex-direction: column;
      ._yes{
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
        background-color: #16284d !important;
        border: none;
        border-radius: 24px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
      ._no{
        width: 100%;
        height: 48px;
        margin-bottom: 20px;
        background: #e2f8f1 !important;
        border: none;
        border-radius: 24px;
        font-size: 16px;
        font-weight: bold;
        color: #3ed0a3;
      }
    }
  }
}

._log-out-btn {
  width: 190px;
  height: 48px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #f2482f;
  background: #fdecea;
  border-radius: 24px;
}
