._hold-lang {
  width: 100%;
  border: 1px solid #dcdee3;
  border-radius: 13px;
  height: auto;
  margin-bottom: 30px;
  position: relative;
  z-index: 1000;

  ._accordion-button {
    width: 100%;
    color: #16284d;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    height: 66px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 13px;
    padding: 20px;

    img {
      width: 20px;
      transition: transform 0.2s ease-in-out;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  ._accordion-toggle {
    height: 0px;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, height 0.3s 0.3s ease-in-out;
    color: #333;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  ._content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ._hold-border {
      margin-bottom: 10px;
    }

    ._hold-all-choices {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 20px;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;

        span {
          font-size: 14px;
          color: #16284d;
          font-weight: bold;
        }

        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
          position: absolute;
          left: -9999px;
        }
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #666;
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 17px;
          height: 17px;
          border-radius: 100%;
          background: #dcdee3;
        }
        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          content: "";
          width: 11px;
          height: 11px;

          background: #3ed0a3;
          position: absolute;
          top: 3px;
          left: 3px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        [type="radio"]:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        [type="radio"]:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        [type="radio"]:checked + label:before {
          border: 1px solid #3ed0a3;
          background-color: #fff;
        }
      }
    }
  }
  ._animated {
    opacity: 1;
    transition: height 0.3s ease-in-out, opacity 0.3s 0.3s ease-in-out;
  }
}
