._settings-modal {
  .modal-content {
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 14px;
    width: 550px;

    .modal-header {
      border-bottom: none;
      button {
        background-color: transparent;
        border: none;
      }
      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #16284d;
      }
    }
  }
}

._setting-btn {
  width: 100%;
  height: 54px;
  margin-top: 40px;
  background-color: #e8ecf0;
  border-radius: 27px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #16284d;
}
