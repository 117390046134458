._platform-is-live {
  margin-top: 40px;
  ._hold-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f6f7f8;
    border-radius: 28px;
    height: 205px;
    padding: 0 40px;

    ._total-inventory {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      position: relative;
      span {
        font-size: 16px;
        color: #9aa1af;
        font-weight: normal;
      }
      h4 {
        font-size: 36px;
        color: #16284d;
        font-weight: bold;
        margin: 0;
      }

      ._dot {
        position: absolute;
        bottom: -15px;
        right: 43%;
      }
    }
  }

  ._hold-your-platform-active-order {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    justify-content: space-between;
  }
  ._hold-your-platform-active-order2 {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-content: space-between;
  }
}
