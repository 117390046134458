._hold-management-filter {
  margin-left: 10px;
  position: relative;
  ._button {
    border: none;
    background-color: transparent;
  }

  ._filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 15px;
    width: 405px;
    height: 550px;
    z-index: 100;
    padding: 24px;

    ._head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 22px;
        font-weight: bold;
        color: #16284d;
      }

      ._close {
        border: none;
        background-color: transparent;
      }
    }

    ._catalog {
      margin-top: 15px;
      h3 {
        font-size: 16px;
        color: #16284d;
        font-weight: bold;
        margin-bottom: 15px;
      }

      ._hold-buttons {
        margin-bottom: 22px;
        button {
          width: auto;
          height: 35px;
          padding: 0 16px;
          font-size: 14px;
          color: #9aa1af;
          border: none;
          background: #f6f7f8;
          border-radius: 20px;
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    ._current-stocks {
      margin-bottom: 25px;
      h3 {
        font-size: 16px;
        color: #16284d;
        font-weight: bold;
        margin-bottom: 15px;
      }
      ._hold-inputs {
        display: flex;
        justify-content: space-between;
        input {
          width: 45%;
          border: none;
          height: 40px;
          padding-left: 14px;

          font-size: 16px;
          color: #16284d;
          outline: none;
          box-shadow: none;
          background: #f6f7f8;
          border-radius: 6px;
          &::placeholder {
            font-size: 14px;
            color: #9aa1af;
          }
        }
      }
    }

    ._price {
      h3 {
        font-size: 16px;
        color: #16284d;
        font-weight: bold;
        margin-bottom: 10px;
      }
      ._hold-all-inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        ._hold-prices-inputs {
          display: flex;
          position: relative;
          width: 45%;

          span {
            position: absolute;
            top: 7px;
            left: 12px;
          }
          input {
            width: 100%;
            border: none;
            height: 40px;
            padding-left: 30px;

            font-size: 16px;
            color: #16284d;
            outline: none;
            box-shadow: none;
            background: #f6f7f8;
            border-radius: 6px;
            &::placeholder {
              font-size: 14px;
              color: #9aa1af;
            }
          }
        }
      }
    }

    ._hold-act-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
      button {
        border: none;
        font-size: 16px;
        font-weight: bold;
        border-radius: 24px;

        &._aplly-btn {
          color: #fff;
          height: 48px;
          background: #16284d;
        }

        &._reset-filter {
          background-color: #f6f7f8;
          height: 48px;
          color: #f2482f;
          margin-top: 24px;
        }
      }
    }
  }
}
