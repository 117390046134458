._hold-inventory {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 10px;
  max-width: 1200px;

  ._hold-nav {
    display: flex;
    justify-content: start;
  }

  ._inventory-items-wrapper {
    width: 100%;
    margin-left: 40px;
    padding-top: 65px;
  }
}
