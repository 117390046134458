.storeBox___ {
  margin-top: 20px;
}

.__hold-management-tabs {
  .wrap-tabs {
    display: flex;
    justify-content: space-between;
    ._tabs {
      .nav-item {
        margin-right: 40px;
        .nav-link {
          width: auto;
          height: 40px;
          background: #e8ecf0;
          border-radius: 20px;
          font-size: 16px;
          color: #9aa1af;
          &.active {
            font-weight: bold;
            color: #fff;
            background-color: #3ed0a3;
          }
        }
      }
    }
  }
}

.faq {
  margin-top: 30px;

  .button-container{
    cursor: pointer;
    padding: 8px 10px;
    width: 170px;
    background: #3ED0A3;
    border-radius: 24px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .create-new-button{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #FFFFFF;
  }

  & > h4 {
    font-size: 30px;
    color: #16284d;
    font-weight: bold;
    margin-bottom: 30px;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f7f8;
    border-radius: 15px;
  }

  .AddRow {
    margin-top: 30px;
    padding: 5px 20px;

    .tipBox {
      padding: 20px;
      border: 0.3px dotted #3ed0a3;
      border-radius: 15px;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .blogBox {
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      padding: 20px;
      border: 0.3px dotted #3ed0a3;
      border-radius: 15px;
      margin: 10px 0;

      display: grid;
      grid-template-columns: 2fr 3fr 1fr;
      .imgBoX {
        width: 100%;
        height: 250;
        border-radius: 15px;

        .bImg {
          width: 100%;
          height: 250;
          border-radius: 15px;
        }
      }
    }

    .imageBox {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      align-items: center;
      .imgIcon {
        width: 200px;
        height: 200px;
        background-color: #9aa1af23;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .img {
          width: 200px;
          height: 200px;
          border-radius: 15px;
        }

        .icon {
          color: #16284dc9;
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .btn-holder {
    justify-content: center;
    align-items: center;
    display: flex;

    ._add-new-item {
      width: 60%;
      height: 48px;
      display: flex;

      align-items: center;
      justify-content: center;
      background: #16284d;
      border-radius: 24px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      border: none;
      margin-bottom: 20px;
    }
  }
  .btn-holder2 {
    justify-content: center;
    align-items: center;
    display: flex;

    ._add-new-item {
      width: 40%;
      height: 48px;
      display: flex;

      align-items: center;
      justify-content: center;
      background: #16284d;
      border-radius: 24px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      border: none;
      margin-bottom: 20px;
    }
  }
}
