._my-store-page {
  // width: 100%;
  margin: 40px;

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 34px;
      color: #16284d;
      font-weight: bold;
    }
  }

  ._hold-store-tabs {
    margin-top: 30px;
    align-items: center;

    .wrap-tabs {
      display: flex;
      justify-content: space-between;
      ._hold-search-sort {
        display: flex;
        gap:20px;
        // justify-content: space-between;
        // align-items: center;
      }
      ._tabs {
        .nav-item {
          margin-right: 20px;
          .nav-link {
            width: auto;
            height: 40px;
            background: #e8ecf0;
            border-radius: 20px;
            font-size: 16px;
            color: #9aa1af;
            &.active {
              font-weight: bold;
              color: #fff;
              background-color: #3ed0a3;
            }
          }
        }
      }
    }
  }
}
