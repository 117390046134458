._staffs {
  margin-top: 20px;
  .wrap-tabs {
    display: flex;

    ._tabs {
      display: flex;
      .nav-item {
        width: 154px;
        height: 40px;
        background: #e8ecf0;
        border-radius: 20px;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        margin-right: 20px;
        .nav-link {
          color: #9aa1af;
          font-weight: normal;
          &.active {
            background-color: #3ed0a3 !important;
            color: #fff;
            font-weight: bold;
            border-radius: 20px;
          }
        }
      }
    }
  }

  ._wrap-all-members {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}
