@use '../../assets/styles/variables';

.user-stats {
    width: 100%;
    background-color: variables.$lighter-gray;
    border-radius: 28px;
    padding: 2em;
    margin-top: 20px;

    .user-text {
        color: variables.$grayed-color;
    }
    .green-dot {
        height: 8px;
        width: 8px;
        background-color: variables.$secondary-color;
        border-radius: 50%;
        display: inline-block;
    }
    .disabled-dot {
        background-color: variables.$disabled-color;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
    }
    .blue-dot {
        height: 8px;
        width: 8px;
        background-color: variables.$primary-color;
        border-radius: 50%;
        display: inline-block;
    }
    .gray-dot {
        height: 8px;
        width: 8px;
        background-color: #F6F7F8;
        border-radius: 50%;
        display: inline-block;
    }
    .red-dot {
        height: 8px;
        width: 8px;
        background-color: #F2482F;
        border-radius: 50%;
        display: inline-block;
    }
    .user-figure {
        color: variables.$primary-color;
        font-weight: 700;
        font-size: 2.25rem;
        margin-top: 0.75rem;
    }
}
.chart {
    width: 12%;
}
.user-items-container {
    border:1px solid  #DCDEE3;
    border-radius: 28px;
    padding: 1.5em;

    .purchase-info-container {
        border-right: dotted 3px variables.$grayed-color;
        position: relative;
        .user-item-info {
            top: 60%;
            left: 40%;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: space-between;
            
            p {
                letter-spacing: 0.22px;
            }
        }
        .user-item-image {
            border-radius: 16px;
            width: 160px;
        }

        .user-dot {
            height: 3px;
            width: 3px;
            background-color: variables.$primary-color;
            border-radius: 50%;
            display: inline-block;
            margin-bottom: 0.25em;
          }
        
    }

}

.user-details-container {
    width: 50%;
    .user-details {
        border-radius: 20px;
        border: 1px solid #E8ECF0; 

        .user-details-header {
            background: #F6F7F8;
            border-radius: 20px 20px 0px 0px;        
        }

    }
}


.newuser-picture-section, .user-info-section { 
    p {
        font-size: 1.25rem;
        font-weight: 700;
    }
}

.profile-img-container{
    position: relative;
    .remove-img-btn {
        position: absolute;
        top: 0;
        left: 33%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -0.5em;
        background-color: hsl(8, 88%, 57%);
        border-radius: 50%;
        width: 30px;
        height:30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 1.25rem;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border: 3px solid #FFFFFF;
        &:hover {
            background-color: #F2482F;
        }
    }
    img {
        border-radius: 24px;
        width: 201px;
        height: 201px;
    }
    
    .upload-btn {
        position: absolute;
        top: 82%;
        left: 40%;
       
    }
}
