@use '../../assets/styles/variables';

.button-container{
    cursor: pointer;
    padding: 8px 10px;
    width: 170px;
    background: #3ED0A3;
    border-radius: 24px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.create-new-button{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
}

.platform-header{ 
    margin: 0 0 2.5em 0;
    .active {
        background-color: variables.$secondary-color;
        color: #fff;
    }
    
    .search-input {
        padding: 0.7rem 0 0.7rem 3.5rem;
        background-color: variables.$lighter-gray;
        border-radius: 12px;
        border: none;
    }

    .search-section {
        width: 30%;
        
        .search-container {
            position: relative;
            align-items: center;
            height: 48px;

            .search-icon {
                position: absolute;
                color: variables.$primary-color;
                top: 20%;
                left: 5%;
                font-size: 2rem;
            }
            .clear-icon {
                position: absolute;
                color: variables.$primary-color-light;
                top: 35%;
                right: 5%;
                font-size: 1rem;
            }
            
          
        }
        .filter-container {
            padding: 0.6rem;
            border-radius: 10px;
            background-color: variables.$lighter-gray;

            .filter-icon {
                font-size: 1.8rem;
                path {
                    stroke: variables.$primary-color;
                    stroke-width: 1px;
                }
            }
           
            .filter-form-container {
                padding: 0;
                border: none !important;
                background-color: #DCDEE3;
                width: 450px;
                transform: translateX(-90%) !important;
                
                #filter-form {
                    padding: 1.5rem 0;
                    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
                    border-radius: 15px;
                    background-color: #fff;
                    .close-sort-container{
                        border-radius: 8px;
                        width: 28px;
                        height: 28px;
                        text-align: center;
                        cursor: pointer;
                        background-color: #E2F8F1;
                    }
                }
                
            }
        }
        .sort-container {
            border-radius: 10px;
            padding: 0.6rem;
            background-color: variables.$lighter-gray;
    
            .sort-icon {
                font-size: 1.8rem;
                path {
                    stroke: variables.$primary-color;
                    stroke-width: 1px;
                }                
            }
           
            .sort-menu-container {
                padding: 0;
                border: none !important;
                background-color: #DCDEE3;
                width: 300px;
                right: 90% !important;
                transform: translateX(-90%) !important;

                #sort-menu {
                    padding: 1.5rem 0;
                    background-color: #fff;
                    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
                    border-radius: 15px;
                    
                    .close-sort-container{
                        border-radius: 8px;
                        width: 28px;
                        height: 28px;
                        text-align: center;
                        cursor: pointer;
                        background-color: #E2F8F1;
                    }
                    

                    .sort-item {
                        padding: 0.75rem 0;
                        font-size: 1.2rem;

                        &::before {
                            content: "";
                            margin-right: 1.5em;
                        }

                        &:hover::before {
                            content: "";
                            border: 5px;
                            border-top-right-radius: 45%;
                            border-bottom-right-radius: 45%;
                            border-left-style: solid;
                            border-color: variables.$secondary-color;
                            margin-right: 1.2em;
                        }
                        
                        &:hover {
                            color: variables.$primary-color;
                            font-weight: 600;
                        }
                    }
                }
            
            }
        }
    }
    
}

.order-header{ 
    margin: 0 0 2.5em 0;
    .active {
        background-color: variables.$secondary-color;
        color: #fff;
    }
    
    .search-input {
        padding: 0.7rem 0 0.7rem 3.5rem;
        background-color: variables.$lighter-gray;
        border-radius: 12px;
        border: none;
    }

    .search-section {
        width: 40%;
        
        .search-container {
            position: relative;

            .search-icon {
                position: absolute;
                color: variables.$primary-color;
                top: 20%;
                left: 5%;
                font-size: 2rem;
            }
            .clear-icon {
                position: absolute;
                color: variables.$primary-color-light;
                top: 35%;
                right: 5%;
                font-size: 1rem;
            }
            
          
        }
        .filter-container {
            padding: 0.6rem;
            border-radius: 10px;
            background-color: variables.$lighter-gray;

            .filter-icon {
                font-size: 1.8rem;
                path {
                    stroke: variables.$primary-color;
                    stroke-width: 1px;
                }
            }
           
            .filter-form-container {
                padding: 0;
                border: none !important;
                background-color: #DCDEE3;
                width: 450px;
                transform: translateX(-90%) !important;
                
                #filter-form {
                    padding: 1.5rem 0;
                    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
                    border-radius: 15px;
                    background-color: #fff;
                    .close-sort-container{
                        border-radius: 8px;
                        width: 28px;
                        height: 28px;
                        text-align: center;
                        cursor: pointer;
                        background-color: #E2F8F1;
                    }
                }
                
            }
        }
        .sort-container {
            border-radius: 10px;
            padding: 0.6rem;
            background-color: variables.$lighter-gray;
    
            .sort-icon {
                font-size: 1.8rem;
                path {
                    stroke: variables.$primary-color;
                    stroke-width: 1px;
                }                
            }
           
            .sort-menu-container {
                padding: 0;
                border: none !important;
                background-color: #DCDEE3;
                width: 300px;
                right: 90% !important;
                transform: translateX(-90%) !important;

                #sort-menu {
                    padding: 1.5rem 0;
                    background-color: #fff;
                    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
                    border-radius: 15px;
                    
                    .close-sort-container{
                        border-radius: 8px;
                        width: 28px;
                        height: 28px;
                        text-align: center;
                        cursor: pointer;
                        background-color: #E2F8F1;
                    }
                    

                    .sort-item {
                        padding: 0.75rem 0;
                        font-size: 1.2rem;

                        &::before {
                            content: "";
                            margin-right: 1.5em;
                        }

                        &:hover::before {
                            content: "";
                            border: 5px;
                            border-top-right-radius: 45%;
                            border-bottom-right-radius: 45%;
                            border-left-style: solid;
                            border-color: variables.$secondary-color;
                            margin-right: 1.2em;
                        }
                        
                        &:hover {
                            color: variables.$primary-color;
                            font-weight: 600;
                        }
                    }
                }
            
            }
        }
    }
    
}
.issues-container {
    border-radius: 10px;
    padding: 0.6rem 0;
    background-color: variables.$lighter-gray;
    
    .issue-dropdown{
        :hover {
            text-decoration: variables.$lighter-gray
        }
    }
    a {
        text-decoration: none;
        :hover {
            color:  variables.$primary-color;
        }
    }
    .issues-menu-container {
        padding: 0;
        border: none !important;
        background-color: #DCDEE3;
        width: 100%;
        margin-top: 0.5em;

        #issues-menu {
            padding: 1.5rem 0;
            background-color: #fff;
            box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
            border-radius: 15px;
            
            .close-sort-container{
                border-radius: 8px;
                width: 28px;
                height: 28px;
                text-align: center;
                cursor: pointer;
                background-color: #E2F8F1;
            }
            

            .issues-item {
                padding: 0.75rem 0;
                font-size: 1.2rem;

                &::before {
                    content: "";
                    margin-right: 1.5em;
                }

                &:hover::before {
                    content: "";
                    border: 5px;
                    border-top-right-radius: 45%;
                    border-bottom-right-radius: 45%;
                    border-left-style: solid;
                    border-color: variables.$secondary-color;
                    margin-right: 1.2em;
                }
                
                &:hover {
                    color: variables.$primary-color;
                    font-weight: 600;
                }
            }
        }
    
    }
}

.back-button {
    background-color: #E8ECF0;
    border-radius: 10px;
    height: 2.5em;
    width: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;

}

.profile-header {
        margin: 5% 0;
}

.logout-btn {
    background: #FDECEA;
    border-radius: 24px;
    width: 192px;
    color: #F2482F;

    &:hover {
        color: #F2482F;
        border-color: #FDECEA;
    }
    &:focus {
        border-color: #FDECEA;
        color: #F2482F;
        box-shadow: 0 0 0 0.25rem #FDECEA;
    }

}

.newuser-header {
    margin: 6% 0 2em 0;

    .title {
        font-size: 2.5rem;
        font-weight: bold;
    }
    .profile-link {
        text-decoration: none;
        color: variables.$primary-color;
    }
}


.user-header{ 
    margin: 0 0 2.5em 0;
    .active {
        background-color: variables.$secondary-color;
        color: #fff;
    }
    
    .search-input {
        padding: 0.7rem 0 0.7rem 3.5rem;
        background-color: variables.$lighter-gray;
        border-radius: 12px;
        border: none;
    }
    .search-section {
        width: 40%;
        
        .search-container {
            position: relative;
            .search-icon {
                position: absolute;
                color: variables.$primary-color;
                top: 20%;
                left: 5%;
                font-size: 2rem;
            }
            .clear-icon {
                position: absolute;
                color: variables.$primary-color-light;
                top: 35%;
                right: 5%;
                font-size: 1rem;
            }
        }
        .filter-container {
            padding: 0.7rem;
            border-radius: 10px;
            background-color: variables.$lighter-gray;

            .filter-icon {
                font-size: 2rem;
                
                path {
                    stroke: variables.$primary-color;
                    stroke-width: 1px;
                }
            }
           
            .filter-form-container {
                padding: 0;
                border: none !important;
                background-color: #DCDEE3;
                width: 450px;
                transform: translateX(-70%) !important;
                
                #filter-form {
                    padding: 1.5rem 0;
                    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
                    border-radius: 15px;
                    background-color: #fff;
                    .close-sort-container{
                        border-radius: 8px;
                        width: 28px;
                        height: 28px;
                        text-align: center;
                        cursor: pointer;
                        background-color: #E2F8F1;
                    }
                }
                
            }
        }
        .sort-container {
            border-radius: 10px;
            padding: 0.6rem;
            background-color: variables.$lighter-gray;
    
            .sort-icon {
                font-size: 2rem;
                path {
                    stroke: variables.$primary-color;
                    stroke-width: 1px;
                }
                
            }
           
            .sort-menu-container {
                padding: 0;
                border: none !important;
                background-color: #DCDEE3;
                width: 300px;
                right: 90% !important;
                transform: translateX(-70%) !important;

                #sort-menu {
                    padding: 1.5rem 0;
                    background-color: #fff;
                    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
                    border-radius: 15px;
                    
                    .close-sort-container{
                        border-radius: 8px;
                        width: 28px;
                        height: 28px;
                        text-align: center;
                        cursor: pointer;
                        background-color: #E2F8F1;
                    }
                    

                    .sort-item {
                        padding: 0.75rem 0;
                        font-size: 1.2rem;

                        &::before {
                            content: "";
                            margin-right: 1.5em;
                        }

                        &:hover::before {
                            content: "";
                            border: 5px;
                            border-top-right-radius: 45%;
                            border-bottom-right-radius: 45%;
                            border-left-style: solid;
                            border-color: variables.$secondary-color;
                            margin-right: 1.2em;
                        }
                        
                        &:hover {
                            color: variables.$primary-color;
                            font-weight: 600;
                        }
                    }
                }
            
            }
        }
    }
    
}

$mid-gray: #dcdee3;
$thicker-gray: #9aa1af;

.gray-btn {
    background-color: $mid-gray;
    color: $thicker-gray;
}
.search-container {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: variables.$grayed-color;
        opacity: 1; /* Firefox */
      }
}

.search-container.spacing-top{
    margin-top:12px;
}