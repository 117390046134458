._account-info {
  width: 100%;
  height: 300px;
  background-color: #f6f7f8;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
  margin-top: 40px;

  ._hold-name-avatar {
    display: flex;
    align-items: center;
    width: 70%;
    ._hold-pic {
      width: 180px;
      height: 180px;
      border-radius: 90px;
      margin-right: 60px;

      img {
        width: 180px;
        height: 180px;
        border-radius: 90px;
        }
    }

    ._hold-name {
      h2 {
        font-size: 32px;
        color: #16284d;
        font-weight: bold;
      }

      ._hold-btns {
        display: flex;
        margin-top: 13px;
        ._admin {
          border: none;
          background: #3ed0a3;
          border-radius: 17.5px;
          width: 150px;
          height: 35px;
          font-size: 16px;
          color: #fff;
          font-weight: normal;
          margin-right: 22px;
        }
      }
    }
  }

  ._phone-email {
    width: 30%;
    ._phone,
    ._email {
      span {
        font-size: 16px;
        color: #777c85;
        font-weight: normal;
      }
      p {
        font-size: 24px;
        color: #16284d;
        font-weight: 700;
      }
    }
  }
}
