._main-analytics {
  padding: 70px 40px 40px 40px;
  width: 100%;
  padding-bottom: 200px;

  ._analytics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;

    h2 {
      font-size: 34px;
      color: #16284d;
      font-weight: bold;
    }
  }

  ._store-summary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #dcdee3;
    border-radius: 28px;
    margin-top: 40px;
    padding: 24px;
    ._head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      h3 {
        font-size: 16px;
        font-weight: bold;
        color: #16284d;
      }
      ._hold-filters {
        display: flex;
        align-items: center;
      }
    }
    ._hold-numbers-of-visitor {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
    }

    .holdTotalProfitCard {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;

      ._profit_card {
        width: 30%;
        height: 102px;
        background: #f6f7f8;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 24px;
        ._no {
          font-size: 15px;
          color: #9aa1af;
          font-weight: normal;
          margin-bottom: 0;
        }

        ._number {
          font-size: 26px;
          color: #16284d;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
      ._profit_card_calc {
        width: 60%;
        height: 102px;
        background: #f6f7f8;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 24px;
        ._no {
          font-size: 15px;
          color: #9aa1af;
          font-weight: normal;
          margin-bottom: 0;
        }

        ._number {
          font-size: 26px;
          color: #16284d;
          font-weight: bold;
          margin-bottom: 0;
        }

        .search-bar {
          display: flex;
          align-items: center;

          width: 304px;
          margin-top: 5px;
          height: 40px;
          background: #fff;
          border-radius: 12px;
          margin-right: 10px;
          ._search-icon {
            display: block;
            padding-right: 15px;
            padding-left: 15px;
          }
          input {
            background: none;
            border: none;
            outline: none;
            height: 100%;
            width: auto;
          }
          input::placeholder {
            color: #9aa1af;
            font-size: 16px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }

  ._current-inventory-total-to-date-order {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .hold-analytics-tabs {
    ._tabs {
      .nav-link {
        background-color: #e8ecf0;
        border-radius: 20px;
        font-size: 16px;
        color: #9aa1af;
        font-weight: normal;
        margin-right: 16px;

        &.active {
          background-color: #3ed0a3;
          font-weight: bold;
          color: #fff;
        }

        &.download {
          background-color: #3ed0a3;
          font-weight: bold;
          color: #fff;
        }
      }
    }

    ._item-summary {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

.detAnalytics {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ._hold-pic {
    img {
      width: 100%;
    }
  }

  ._info {
    text-align: center;
    margin-top: 30px;
    h3 {
      font-size: 20px;
      color: #16284d;
      font-weight: bold;
    }
  }
}

.categorySelectDiv_ {
  margin: 40px 0;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  select {
    width: 200px !important;
  }
}
