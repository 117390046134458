._management-website {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 110px;
  ._hold-pic {
    img {
      width: 100%;
    }
  }

  ._info {
    text-align: center;
    margin-top: 30px;
    h3 {
      font-size: 20px;
      color: #16284d;
      font-weight: bold;
    }
    ._add-new-platform {
      margin-top: 23px;
      width: 330px;
      height: 48px;
      color: #fff;
      font-weight: bold;
      background-color: #3ed0a3;
      border-radius: 24px;
      border: none;
    }
  }
}

._management-all_mobile-apps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 100%;
  overflow: hidden;

  .boxMember {
    display: flex;
    flex-direction: column;
    border: 1px solid #dcdee3;
    border-radius: 28px;
    margin-top: 32px;
    width: 100%;
    max-width: 100%;
    height: 180px;
    padding: 24px;
    align-items: center;

    button {
      width: 190px;
      height: 48px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      color: #fff;
      background-color: #16284d;
      margin-top: 20px;

      &:disabled {
        background: #dcdee3;
        color: black;
        cursor: not-allowed;
      }
    }
  }
}
