@use "../../../../assets/styles/variables";

.custom-filter-wrapper {
  width: 68px;
  margin-right: 10px;
  position: relative;
  z-index: 9999999;
}
._sort-icon {
  background: none !important;
  outline: none;
  border: none;
}

.sort-container {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
  // margin-left: 10px;
  // background-color: variables.$lighter-gray;

  .sort-menu-container {
    padding: 0;
    border: none !important;
    border-radius: 15px;
    width: 300px;

    #sort-menu {
      padding: 1.5rem 0;
      background-color: #fff;
      box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
      border-radius: 15px;

      .close-sort-container {
        border-radius: 8px;
        width: 28px;
        height: 28px;
        text-align: center;
        cursor: pointer;
        background-color: #e2f8f1;
      }

      .sort-item {
        padding: 0.75rem 0;
        font-size: 1.2rem;

        &::before {
          content: "";
          margin-right: 1.5em;
        }

        &:hover::before {
          content: "";
          border: 5px;
          border-top-right-radius: 45%;
          border-bottom-right-radius: 45%;
          border-left-style: solid;
          border-color: variables.$secondary-color;
          margin-right: 1.2em;
        }

        &:hover {
          color: variables.$primary-color;
          font-weight: 600;
        }
      }
    }
  }
}
