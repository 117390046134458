* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
}

body {
  background-color: #ffffff;
}

.cursor {
  cursor: pointer;
}
.screenRow {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 10px;
  min-height: 100vh;
}

.sideBar {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #dcdee3;
  padding: 20px;
  padding-left: 40px;
}

.unScrollableSideBar {
  position: sticky;
  position: -webkit-sticky;
  top: -20px;
  background-color: white;
  z-index: 1000;
}

.sideBar::-webkit-scrollbar {
  width: 5px;
}

.sideBar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sideBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin: 0 2px;
  width: 3px;
  height: 3px;
  background-color: rgb(219, 216, 221);
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 6px;
  background-color: #373f4ed2;
}

.mainScreen {
  padding: 10px 20px;
}

.arrowBoxGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.arrowBox {
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background-color: #e8ecf0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.LogoGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 62px;
}

.ActivateBtnGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 62px;
}

.threeMenuBox {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: #e8ecf0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.activateButton {
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 24px;
  background-color: #16284d;
  color: #fff;

  &:disabled {
    background-color: #e8ecf0;
    cursor: not-allowed;
  }
}

.activateNewButton {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 24px;
  background-color: #16284d;
  color: #fff;
}

.tint {
  color: #16284d !important;
}

.bg-tint {
  background-color: #16284d !important;
}

.topDropdown {
  background-color: #3e4452;
  color: #fff;
  width: 280px;
  border-radius: 10px;
}

.topFlexMain {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saveButton {
  width: 140px;
  height: 40px;
  border: none;
  border-radius: 24px;
  background-color: #3ed0a3;
  color: #fff;
}

.publishButton {
  height: 40px;
  padding: 0 10px;
  border: none;
  border-radius: 5px;
  background-color: #16284d;
  color: #fff;
}

.disabledpublishButton {
  height: 40px;
  padding: 0 10px;
  border: none;
  border-radius: 5px;
  background-color: #6a83b6;
  opacity: 0.8;
  color: #fff;
  cursor: not-allowed;
}

.newButton {
  height: 40px;
  border: none;
  border-radius: 20px;
  padding: 20px;
  background-color: #3e4452;
  color: gold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainScreenTopBox {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 10;
  background-color: #fff;

  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layoutBox {
  padding: 15px;
  min-height: 200px;
  border-radius: 12px;
  width: 100%;
  background-color: #f6f7f8;
}

.markupDiv {
  padding: 20px;
  background-color: #dcdee3;
  border-radius: 15px;
  min-height: 80vh;
}

.LayoutGridBox {
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  border-radius: 5px;
  cursor: move;
}

.LayoutGrid2Box {
  background-color: #f6f7f8;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  border-radius: 5px;
}

.LayoutGridBox:hover {
  transform: scale(0.98);
}

.opacity {
  opacity: 0.7;
}

.angleDrop {
  display: flex;
  justify-self: flex-end;
  cursor: pointer;
}

.dragHandle {
  cursor: move;
}
.dragHandle2 {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: #d8dbe2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.current {
  background-color: #c3e4da;
}

.previewTXT {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.topBoxContainer {
  width: 50%;
}

.template_build_name {
  width: 70%;
}

.componentBuildBox {
  position: relative;
}

.cmp {
  font-weight: 900;
  font-size: 30px;
}

.cmpMoveIcon {
  background-color: white;
  color: #16284d;
  position: absolute;
  left: -15px;
  top: -10px;
  z-index: 900;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: grab;
  opacity: 0.9;
  display: none;
}

.deleteFlex {
  position: absolute;
  left: 30px;
  top: -14px;
  padding: 5px;
  display: flex;
  width: 8%;
  border: none;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.editIcon {
  font-weight: 900;
  font-size: 20px;
}

.deleteIcon {
  font-weight: 900;
  font-size: 20px;
}

.deleteIDiv {
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #16284d;
  opacity: 0.9;
  cursor: pointer;
  z-index: 900;
  padding: 5px;
}

.editIDiv {
  background-color: white;
  padding: 5px;
  color: #16284d;
  opacity: 0.9;
  cursor: pointer;
  z-index: 900;
  border: none;
  border-radius: 5px;
}

.componentBuildBox:hover .cmpMoveIcon {
  display: block;
}

.componentBuildBox:hover .deleteFlex {
  display: flex;
}

.undoRedo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.undoredobtn {
  padding: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #071022;
  border: none;
}

.editingDiv {
  background-color: #e8ecf0;
  border-radius: 5px;
  padding: 10px;
}

.modalRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.myModals {
  background-color: black;
  padding: 20px;
}

.topModalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal {
  width: 60%;
}

.vConfirmButton {
  background-color: #fff;
  color: black;
  font-weight: bold;
  outline: none;
  border-radius: 0px;
  padding: 10px;
}

.vConfirmButton:focus {
  outline: none;
}

.emailForgot {
  background-color: black;
  border: 1px solid#3e4452;
  color: white;
  font-weight: bold;
}

.emailForgot:focus {
  background-color: black;
  border: 1px solid#3e4452;
  outline: none;
  color: white;
}

.otpBack {
  width: 100%;
  display: grid !important;
  gap: 10px;
  grid-template-columns: repeat(6, 1fr);
}

.otpBox {
  width: 50px !important;
  height: 40px !important;
  background-color: black;
  color: white;
  border: 1px dotted#3e4452;
}

.nairobiDivTop {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr;
  justify-content: center;
  align-items: center;
  /* gap: 5px; */
}

.sideNavNairobi {
  display: flex;
  justify-content: start;
  align-items: center;
}

.closeNairobiBtn {
  background-color: #16284d;
}

.fourthBannerGrid1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  grid-template-rows: 1fr 2fr;
  max-height: 100%;
}

.fourthBannerGrid2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  grid-template-rows: 2fr 1fr;
  max-height: 100%;
}
