@use '../../assets/styles/variables';
@import '../../assets/styles/media-queries';

.description-container {
    position: relative;

    .platform-description {
        position: absolute;
        bottom: 0;
        left: 5%;
        margin-left: 20px;
        .card-type{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.225px;
            color: #9AA1AF;
        }
        .card-category{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 24px;
            letter-spacing: 0.325px;
            color: #16284D;
        }
        .card-button-paltforms{
            border: 1px solid #DCDEE3;
            border-radius: 15.5px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 14px;
            /* identical to box height, or 88% */
background-color: transparent;
            letter-spacing: 0.2px;

            color: #16284D;
            padding: 8px 14px;
        }
    }
    
    .dots {
        position: absolute;
        right: 0;
        .dropdown-container {
            border: none;
            width: 236px;
            right: 50% !important;
            transform: translateX(-50%) !important;
        }
        #options-menu{ 
            box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
            border-radius: 15px;

            .options-item {
                padding: 1rem;
                width: 100%;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }

    .live-status{ 
        border: 1px solid #DCDEE3;
        border-radius: 15.5px;
        padding: 0.5rem 0.7rem;
        width: 100%;
        margin-top: 1.25rem;
    }
    .three-dots{
        background-color: #E8ECF0;
        width: 2rem;
        border-radius: 5px;
        padding: 0 auto;
    }
  
    .dropdown-toggle {
        &:after {
            display: none;
        }
    }
   
}
.platform-stats {
    .green-bar {
        left: 10% !important;
    }
}
