._mudita-btn {
  width: 160px;
  height: 32px;
  background: #e8ecf0;
  border-radius: 7px;

  border: none;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}
