// Customize FieldInput
._date-input {
  margin: 27px 0;
  display: flex;
  position: relative;
  ._icon-date {
    position: absolute;
    right: 16px;
    top: 4px;
  }
  .react-datepicker__input-container {
    input {
      width: 220px;
      height: 40px;
      background: #f6f7f8;
      border-radius: 6px;
      border: none;
      padding: 0 14px;
      font-size: 14px;
      color: #16284d;
      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.react-datepicker {
  border: none !important;
  width: 290px;
  background-color: #fff;

  box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
  border-radius: 15px 0px 15px 15px;
}
.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
  padding-top: 25px !important;
}
.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__navigation--next {
  border-radius: 50%;
  background: #e2f8f1 !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  color: #3ed0a3 !important;
  border-width: 2px 2px 0 0 !important;
}

.react-datepicker__navigation-icon--next {
  left: 0px !important
  ;
}

.react-datepicker__navigation--next {
  right: 10px !important;
  top: 17px !important;
}

.react-datepicker__navigation--previous {
  border-radius: 50%;
  background: #e2f8f1 !important;
}

.react-datepicker__navigation-icon--previous {
  right: 0px !important;
}

.react-datepicker__navigation-icon {
  top: 4px !important;
}
.react-datepicker__navigation--previous {
  left: 10px !important;
  top: 17px !important;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  display: none !important;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  display: none !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day-names {
  padding-top: 20px;
}

.react-datepicker__day--disabled {
  color: #dcdee3 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: red;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.1rem !important;
}

.react-datepicker__current-month {
  color: #16284d !important;
}

.react-datepicker__day-name {
  font-size: 10px;
  color: #16284d !important;
}

.react-datepicker__day--selected {
  background-color: transparent !important;
  color: #3ed0a3 !important;
  font-weight: bold;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: #3ed0a3 !important;
  font-weight: bold;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: transparent;
}

.react-datepicker__day--today {
  color: #3ed0a3 !important;
  background-color: transparent !important;
}
