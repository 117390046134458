._free-account {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ._title {
      display: flex;
      align-items: center;

      h3 {
        font-size: 34px;
        color: #16284d;
        font-weight: bold;
        margin-left: 30px;
      }
    }
  }
}
