.rc-dropdown {
  width: 314px;
  height: 240px;
  top: 122px !important;
  // background: #ffffff;
  // box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
  // border-radius: 15px;
}

.rc-dropdown-menu {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
  border-radius: 15px;
  // background: #ffffff;
  // box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
  // border-radius: 0px 0px 15px 15px;
  border: none;
}

.rc-dropdown-menu-item {
  cursor: pointer;
  font-size: 14px !important;
  color: #9aa1af !important;
  font-weight: normal !important;
}

.rc-dropdown-menu-item-selected {
  font-size: 14px !important;
  color: #16284d !important;
  font-weight: bold;
  background-color: transparent !important;
}

.rc-dropdown-menu > .rc-dropdown-menu-item-selected:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  background: #3ed0a3;
  border-radius: 0px 100px 100px 0px;
  width: 3px;
  height: 24px;
}

._manag-head-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0;
  h3 {
    font-size: 22px;
    color: #16284d;
    font-weight: bold;
  }

  button {
    background-color: transparent;
    border: none;
  }
}

.rc-dropdown-menu-item {
  padding: 10px 20px !important;
}

._wrap-sort {
  // position: relative;
  margin-left: 10px;
  ._mang-sort-button {
    border-radius: 7px;
    background-color: transparent;
    border: none;
  }
}
