@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;800&display=swap');

$primary-color: #16284D;
$primary-color-light: #777C85; 
$danger-color: #F2482F;
$secondary-color: #3ED0A3;
$font-family: 'Montserrat',sans-serif;
$grayed-color: #9AA1AF;
$disabled-color: #DCDEE3;
$light-gray: #E8ECF0;
$lighter-gray: #F6F7F8;
$light-gray2: #D8DBDD;
$mid-gray: #dcdee3;
