._active-order {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dcdee3;
  border-radius: 28px;
  padding: 24px;
  ._order-status {
    padding: 40px;
    display: flex;
    flex-direction: column;
    background: #f6f7f8;
    border-radius: 13px;

    ._order-number {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ._number {
        span {
          font-size: 13px;
          color: #9aa1af;
          font-weight: normal;
        }
        h3 {
          font-size: 26px;
          font-weight: bold;
          margin: 0;
          &._grey {
            color: #9aa1af;
          }
          &._cancel {
            color: #f2482f;
          }

          &._big-main-color {
            color: #16284d;
          }
          &._big-second-main {
            color: #3ed0a3;
          }
        }
      }
    }

    ._grap {
      margin-top: 17px;
    }
  }

  ._active {
    margin-top: 24px;

    h2 {
      font-size: 16px;
      color: #16284d;
      font-weight: bold;
    }

    ._order-info {
      width: 100%;
      border: 1px solid #dcdee3;
      border-radius: 13px;
      margin-top: 20px;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      ._name {
        h3 {
          font-size: 18px;
          color: #16284d;
          font-weight: bold;
          margin: 0;
        }
        ._avatar {
          display: flex;
          margin-top: 10px;
          p {
            margin-left: 10px;
          }
        }
      }

      ._trans-date,
      ._total-price {
        span {
          font-size: 13px;
          color: #777c85;
          font-weight: normal;
        }
        p {
          font-size: 16px;
          color: #16284d;
          font-weight: bold;
        }
      }
    }
  }
}
