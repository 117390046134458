._delete-modal {
  .modal-content {
    width: 466px;
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 24px;
    padding: 0 15px;
    .modal-header {
      display: flex;
      justify-content: end;
      border-bottom: none;

      button {
        border: none;
        background: transparent;
      }
    }

    .modal-body {
      display: flex;
      justify-content: center;

      ._hold-content {
        text-align: center;
        width: 100%;
        h2 {
          font-size: 18px;
          color: #16284d;
          font-weight: bold;
        }
        p {
          font-size: 15px;
          color: #777c85;
          font-weight: normal;
        }

        button {
          width: 100%;
          height: 48px;
          display: block;
          border-radius: 24px;
          font-size: 16px;
          font-weight: bold;
          border: none;

          &._yes {
            color: #fff;
            background-color: #3ed0a3;
            margin-bottom: 20px;
          }

          &._no {
            color: #3ed0a3;
            background-color: #e2f8f1;
          }
        }
      }
    }
  }
}
