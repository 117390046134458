.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}
._this-month {
  .modal-content {
    width: 570px !important;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 24px;
    padding: 32px;

    ._hold-this-month-content {
      padding: 30px 20px 0;
    }
    .modal-header {
      margin-bottom: 15px;
      padding: 0;
      border: none;
      ._close-modal {
        background-color: transparent;
        border: none;
      }
    }
    .modal-title {
      font-size: 22px;
      color: #16284d;
    }

    ._select-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      h2 {
        font-size: 16px;
        color: #16284d;
        font-weight: normal;
      }
      .form-check-input {
        border-radius: 50%;
        background-color: #dcdee3;
        width: 17px;
        height: 17px;
        border: none;
        cursor: pointer;
        box-shadow: none;
      }
      .form-check-input:checked[type="checkbox"] {
        background-color: #3ed0a3;
        box-shadow: none;
      }
    }

    ._confirm {
      button {
        width: 100%;
        height: 48px;
        background: #16284d;
        border-radius: 24px;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        border: none;
      }
    }

    ._date {
      h2 {
        font-size: 16px;
        color: #16284d;
        font-weight: bold;
        margin: 0;
      }
      ._hold-date {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

._this-month-btn {
  background-color: #e8ecf0;
  border-radius: 7px;
  border: none;
  width: 158px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #16284d;
  padding: 0 10px;
  margin-right: 20px;
  &:focus {
    background-color: transparent;
    border: none;
  }
}
