.sidebar_style {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  width: 100%;
  border-color: #dcdee3;
  border-style: solid;
  border-right-width: 1px;
  padding-top: 70px;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    margin: 0 2px;
    width: 5px;
    background-color: rgb(219, 216, 221);
  }

  .app_logo {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    padding: 20px;
    top: -70px;
    background-color: white;
    z-index: 1000;
    img {
      width: 100%;
    }
  }
  .link_style {
    margin-bottom: 40px;
    a {
      color: #9aa1af;
      text-decoration: none;
    }
    .figure {
      // width: 26px;
      // height: 26px;
      margin-right: 16px;
      margin-left: 28px;
    }
    svg {
      path {
        fill: #9aa1af;
      }
    }
  }
  .selected {
    color: #16284d !important;
    display: flex;
    // justify-content: space-between;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: #3ed0a3;
      border-radius: 0px 100px 100px 0px;
      width: 5px;
      height: 40px;
    }
    svg {
      path {
        fill: #3ed0a3;
      }
    }
  }

  ._my-account {
    width: 100%;
    // height: 65px;
    background-color: #f6f7f8;
    border-radius: 12px;
    padding: 14px;
    margin-bottom: 45px;
    a {
      text-decoration: none;
    }
    ._hold-user-info {
      display: flex;
      // justify-content: space-between;

      position: relative;

      ._avatar {
        margin-right: 10px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 25px;
        }
      }

      ._name {
        h3 {
          font-size: 14px;
          color: #16284d;
          font-weight: bold;
          margin: 0;
        }
        span {
          font-size: 12px;
          color: #3e4452;
          font-weight: normal;
        }
      }

      ._arrow-right {
        position: absolute;
        right: 0;
        top: 4px;
      }
    }
  }
}

.logoutBtn {
  display: flex;
  padding: 15px;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.platformRowsEa {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 10px 20px;
  align-items: center;

  .tinTwer {
    display: flex;
    align-items: baseline !important;
    justify-content: center;
    h5 {
      color: #16284d;
      font-weight: bold;
    }
  }

  .tYYn {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #23ad83;
    cursor: pointer;
  }
}
.storeSelectI {
  margin: 0 20px 20px 20px;
  height: 48px;
}
