._current-inventory {
  width: 35%;
  height: auto;
  background: #f6f7f8;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  ._circle {
    margin-bottom: 40px;
  }

  ._data {
    width: 100%;
    text-align: center;
    ._total {
      h2 {
        font-size: 14px;
        color: #9aa1af;
        font-weight: normal;
      }
      span {
        font-size: 28px;
        color: #16284d;
        font-weight: bold;
      }
    }

    ._items-for-sale {
      display: flex;
      justify-content: space-between;
      width: 100%;
      ._item-sold {
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 14px;
          color: #9aa1af;
          font-weight: normal;
          margin: 0;
        }
        span {
          font-size: 28px;
          color: #3ed0a3;
          font-weight: bold;
        }
      }

      ._item-for-sale {
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 14px;
          color: #9aa1af;
          font-weight: normal;
          margin: 0;
        }
        span {
          font-size: 28px;
          color: #9aa1af;
          font-weight: bold;
        }
      }
    }
  }
}
