@use "../../assets/styles/variables";
@import "../../assets/styles/media-queries";

.order-stats {
  width: 100%;
  background-color: variables.$lighter-gray;
  border-radius: 28px;
  padding: 2em;

  .order-stats-content {
    position: relative;

    .green-bar {
      background-color: variables.$secondary-color;
      height: 7px;
      width: 90px;
      position: absolute;
      bottom: 1%;
      left: 8%;
      z-index: 1;
      border-radius: 100px 100px 0px 0px;
      @include respond-below(sm) {
        display: none;
      }
    }
  }
  .order-text {
    color: variables.$grayed-color;
  }
  .green-dot {
    height: 8px;
    width: 8px;
    background-color: variables.$secondary-color;
    border-radius: 50%;
    display: inline-block;
  }
  .gray-dot {
    background-color: #f6f7f8;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
  }
  .blue-dot {
    height: 8px;
    width: 8px;
    background-color: variables.$primary-color;
    border-radius: 50%;
    display: inline-block;
  }
  .red-dot {
    height: 8px;
    width: 8px;
    background-color: #f2482f;
    border-radius: 50%;
    display: inline-block;
  }
  .order-figure {
    color: variables.$primary-color;
    font-weight: 700;
    font-size: 2.25rem;
    margin-top: 0.75rem;
  }
}
.recent-order-stats {
  background-color: variables.$lighter-gray;
  border-radius: 28px;
  padding: 1.5rem;

  .order-text {
    color: variables.$grayed-color;
  }

  .order-figure {
    color: variables.$primary-color;
    font-weight: 700;
    font-size: 2.25rem;
    margin-top: 0.75rem;
  }
}

.recent-order {
  border: 1px solid #dcdee3;
  border-radius: 28px;
  margin: 1.5rem 0;
  padding: 1.75rem 1rem;

  h4 {
    font-size: 1em;
    font-weight: 700;
  }
}

.platforms-list {
  height: 70vh;
  overflow-y: scroll;
}
.platforms-list::-webkit-scrollbar {
  display: none;
}
.platforms-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chart {
  width: 12%;
}
.dropdown-toggle {
  &::after {
    margin-left: 1.5rem;
    color: variables.$primary-color;
  }
  &::before {
    margin-left: 1.5rem;
  }
}
.dropdown-menu {
  min-width: 9.5rem;
  border: 1px solid #fff;
  border-radius: 12px 15px;
}

.dropdown-item {
  color: variables.$grayed-color;
  padding: 0.5em 0;

  &::before {
    content: "";
    margin-right: 1.5em;
  }
  &:hover::before {
    content: "";
    border: 5px;
    border-top-right-radius: 45%;
    border-bottom-right-radius: 45%;
    border-left-style: solid;
    border-color: variables.$secondary-color;
    margin-right: 1em;
  }
  &:hover {
    background-color: #fff;
    color: variables.$primary-color;
    padding-right: 0;
  }
  &:focus {
    background-color: #fff;
    color: variables.$primary-color;
    padding-right: 0;
  }
}
.statsFlexBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  .latets-platform-box {
    background-color: variables.$lighter-gray;
    padding: 1.75rem 1.5rem 1.75rem 1.5rem;
    border-radius: 13px;
    margin: 1.25rem 0;
  }
}
