._product {
  margin-right: 25px;
  border: none;

  .card-img-top {
    border-radius: 14px;
  }
  .card-title {
    font-size: 20px;
    font-weight: bold;
    color: #16284d;
  }

  ._price-info-sold-item {
    b {
      font-size: 18px;
      font-weight: 600;
      color: #16284d;
    }
    span {
      margin: 0 10px;
    }
    small {
      font-size: 18px;
      font-weight: normal;
      color: #777c85;
    }
  }
  &:last-child {
    margin-right: 0;
  }

  .card-body {
    padding-left: 0;
    position: relative;
  }

  ._product-details {
    width: 34px;
    height: 34px;
    background-color: #d3d5d7;
    border-radius: 8px;
    border: none;
    position: absolute;
    top: -50px;
    right: 10px;
  }

  ._details-box {
    width: 250px;
    height: 207px;
    position: absolute;
    top: -230px;
    left: 15px;
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 15px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    // justify-content: center;

    text-align: left;
    button {
      background: none;
      border: none;
      padding: 0 0 10px 0;
      font-size: 16px;
      color: #16284d;
      font-weight: 500;
      text-align: left;
      &._del-item {
        color: #f2482f;
      }
    }

    ._close-detail-box {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
