.active-order-card {
  margin-top: 35px;
  height: auto;
  border: 1px solid #f2f2f2 !important;
  border-radius: 20px !important;
  display: flex;
  justify-content: space-between;

  padding: 24px;
  ._hold-inof-details {
    display: grid;
    grid-template-columns: 40% 55%;
    justify-content: space-between;
    gap: 10px;
    ._name-id {
      width: 100%;
      display: flex;
      align-items: center;
      border: none;
      border-right: 2px dashed #dcdee3;
      padding-right: 20px;
      ._picture {
        margin-right: 20px;
        width: 160px;
        height: 160px;
        border-radius: 10px;
        overflow: hidden;

        img{
          width: 160px;
          height: 160px;
          border-radius: 10px;
  
        }
      }

      ._info {
        ._name {
          display: flex;
          align-items: center;
          span {
            margin-right: 10px;
          }
          h3 {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0;
            color: #16284d;
          }
        }
        ._id {
          font-size: 18px;
          color: #16284d;
          font-weight: bold;
          margin-top: 10px;
        }
        ._purchased {
          display: flex;
          // align-items: center;
          p {
            font-size: 16px;
            margin: 0 10px;
            color: #777c85;
            font-weight: normal;
            &:first-child {
              margin-left: 0;
            }
          }
          ._cancelled-text {
            color: #f2482f;
          }
        }
      }
    }

    ._status-details {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      ._data-payment-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
          font-size: 13px;
          color: #777c85;
          font-weight: normal;
        }
        h5 {
          font-size: 16px;
          color: #16284d;
          font-weight: 500;
        }
        h6 {
          font-size: 13px;
          color: #777c85;
          margin-bottom: 0;
          font-weight: normal;
        }
        p {
          font-size: 16px;
          color: #16284d;
          font-weight: 500;
        }
      }

      ._items_ {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ._numbers {
          h4 {
            font-size: 13px;
            color: #777c85;
            font-weight: normal;
            margin-bottom: 0;
          }
          p {
            font-size: 16px;
            color: #16284d;
            font-weight: bold;
            margin-bottom: 0;
          }
        }
        ._total-price {
          h4 {
            font-size: 13px;
            color: #777c85;
            font-weight: normal;
            margin-bottom: 0;
          }
          p {
            font-size: 16px;
            color: #16284d;
            font-weight: bold;
            margin-bottom: 0;
          }
        }
      }

      ._actions {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        ._btn-actions {
          display: flex;
          flex-direction: column;
          .btn {
            width: 125px;
            height: 35px;
            margin-top: 20px;
            // margin-bottom: 16px;
            font-size: 14px;
            color: #fff;
            font-weight: normal;
            border: none;
            border-radius: 20px;
            &:first-child {
              background: #16284d;
            }
            &._btn-proceed {
              background: #16284d !important;
            }
          }
        }

        a {
          background: none;
        }
      }
    }
  }
}
