@use '../../assets/styles/variables';


.modal-content {
    padding: 2rem;
}

.modal-header {
    border-bottom: 1px solid #fff;
}

.modal-input {
    background: variables.$lighter-gray;
    border-radius: 12px;
    padding: 1rem;
}

.reset-input {
    position: relative;
    .icon-eye {
        position: absolute;
        left: 92%;
        top: 32%;
        color: variables.$primary-color;
      }
}

.close-btn {
    background-color: #E8ECF0 !important;
 }

.no-btn {
        background-color: #E2F8F1;
        color: variables.$secondary-color;
        border-color: #E2F8F1;
        border-style: none;
        border-radius: 24px;
        padding: 0.6rem 0 0.6rem 0;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.2rem;
}
#upload-btn {
    display: inline-flex;
    align-items: center;
    background-color: variables.$primary-color;
    color: #fff;
    border-radius: 20px;
}
.rename-input-container{
    margin-bottom: 2em;
}


 #platform{ 
    max-width: 600px;
 }

 .user-info-container{ 
     position: relative;

    .user-item-image {
        border-radius: 16px;
        width: 160px;
    }
    .user-item-info {
            bottom: 0;
            left: 34%;
            position: absolute;
            display: flex;
            flex-direction: column;

            .username {
                font-size: 1.125rem;
                font-weight: 500;
            }
            .user-dot {
                height: 3px;
                width: 3px;
                background-color: #16284D;
                border-radius: 50%;
                display: inline-block;
                margin-bottom: 0.25em;
            }
    }
    .user-details {
        margin-top: 40px;
    }
    
 }
 .buyer-orders {
     height: 300px;
     overflow: scroll;
     -ms-overflow-style: none;  /* IE and Edge */
     scrollbar-width: none;  /* Firefox */
 }
 .buyer-orders::-webkit-scrollbar {
    display: none;
}

.buyer-order-container {
    position: relative;
    border: 1px solid #DCDEE3;
    border-radius: 28px;
    padding: 1.5em;
    margin-bottom: 1.5em;
    
    .buyer-order-image {
        border-radius: 16px;
        width: 130px;
    }
    .buyer-order-info {
        position: absolute;
        bottom: 0;
        left: 22%;
        margin-left: 1.5em;
        margin-bottom: 1.5em;
        
        .transaction-id {
            font-size: 1.25rem;
            font-weight: 600;
        }
        .transaction-date {
            font-weight: 500;
            font-size: 0.875em;
        }
    }
 }


 .report-dropdown-container {
        .btn {
            &:focus {
                border: none !important;
                outline: none !important;
                box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0);
            } 
            &:active {
                border: none !important;
                outline: none !important;
            } 
        }
        .report-dropdown-menu{ 
            padding: 0 !important;
            border: none !important;
            background-color: #eaebee;
            width: 100%;
            margin-top: -3em;
            /*transform: translateX(-50%) !important;
            left: 50% !important;
            top: 100% !important;*/
            #report-status-menu {
                background-color: #fff;
                box-shadow: 0px 5px 25px -1px rgb(22 40 77 / 18%);
                border-radius: 15px;

                .status-item {
                    padding: 1em 1.5em;
                    width: 100%;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
           
        }
 }
 .store {
    font-weight: 500;
    padding: 0.5em 1em;
    background-color: #DCDEE3;
    margin-right: 0.5em;
    border-radius: 20px;
    cursor: pointer;
}
.associated-order-container {
    position: relative;
    border: 1px solid #DCDEE3;
    border-radius: 28px;
    padding: 1.5em;

    .associate-order-image {
        border-radius: 16px;
        width: 130px;
    }

    .associate-order-info {
        position: absolute;
        bottom: 0;
        left: 22%;
        margin-left: 1.5em;
        margin-bottom: 1.5em;
        

        .transaction-id {
            font-size: 1.25rem;
            font-weight: 600;
        }
        .transaction-date {
            font-weight: 500;
            font-size: 0.875em;
        }
    }
}
.ticket-status {
    border: 1px solid variables.$mid-gray;
    border-radius: 15.5px;
}