._item-summary-details {
  .modal-content {
    width: 605px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 5px 25px -1px rgba(22, 40, 77, 0.183976);
    border-radius: 24px;
    padding: 30px;
    .modal-header {
      border: none;
      padding: 0;
      .modal-title {
        font-size: 22px;
        color: #16284d;
      }
      ._close-modal {
        background-color: transparent;
        border: none;
      }
    }
  }
}
._hold-this-item-summary-details {
  ._sold-availabilty {
    width: 100%;
    border: 1px solid #dcdee3;
    border-radius: 14px;
    padding: 0 24px;

    .graphColumn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ._hold-graph {
        width: 122px;
        height: 122px;
        // margin-right: 30px;
      }

      ._item-sold {
        display: flex;
        flex-direction: column;
        margin-left: -30px;
        ._sold {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;
        }
        h3 {
          font-size: 16px;
          color: #16284d;
          font-weight: normal;
          margin: 0;
        }
        span {
          margin-right: 8px;
        }
      }

      ._filtering-details {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        button {
          margin-right: 0;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          &:focus {
            background-color: #e8ecf0;
          }
        }
      }
    }

    .itemProfitCalculaterGrid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      ._profit_card {
        width: 80%;
        margin: 10px 0;
        height: 102px;
        background: #f6f7f8;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 24px;
        ._no {
          font-size: 15px;
          color: #9aa1af;
          font-weight: normal;
          margin-bottom: 0;
        }

        ._number {
          font-size: 26px;
          color: #16284d;
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      ._profit_card_calc {
        width: 80%;
        height: 102px;
        margin: 10px 0;
        background: #f6f7f8;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 24px;
        ._no {
          font-size: 15px;
          color: #9aa1af;
          font-weight: normal;
          margin-bottom: 0;
        }

        ._number {
          font-size: 26px;
          color: #16284d;
          font-weight: bold;
          margin-bottom: 0;
        }

        .search-bar {
          display: flex;
          align-items: center;

          width: 304px;
          margin-top: 5px;
          height: 40px;
          background: #fff;
          border-radius: 12px;
          margin-right: 10px;
          ._search-icon {
            display: block;
            padding-right: 15px;
            padding-left: 15px;
          }
          input {
            background: none;
            border: none;
            outline: none;
            height: 100%;
            width: auto;
          }
          input::placeholder {
            color: #9aa1af;
            font-size: 16px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }

  ._hold-number-of-visitor-in-modal {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    ._number-of-visitor {
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 260px;
      margin-bottom: 24px;
    }
  }
}

// OrderCard
._items {
  width: 465px;
  height: 175px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;
  &._add-border {
    padding-left: 20px;

    border: 1px solid #dcdee3;
  }
  ._hold-order-image {
    width: 135px;
    height: 135px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  ._order-details {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    ._item-name {
      font-size: 18px;
      color: #9aa1af;
      font-weight: normal;
    }
    ._items-sold {
      display: flex;
      align-items: baseline;
      p {
        font-size: 26px;
        color: #16284d;
        font-weight: bold;
        margin: 0;
      }
      span {
        margin: 0 9px;
        font-size: 20px;
      }
    }
  }

  ._icon-down {
    position: absolute;
    right: 20px;
    // top: 40%;
  }
}
