.customFileInput {
  label {
    background-color: #16284d;
    color: white;
    padding: 0.5rem;
    font-family: sans-serif;
    // border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
    width:160px;
    text-align:center;
    border-radius:25px;
  }

  #file-chosen {
    margin-left: 0.3rem;
    font-family: sans-serif;
  }

}
