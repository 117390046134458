// @import "~react-pro-sidebar/dist/scss/styles.scss";
._inventory-sidebar {
  border-right: 1px solid #dcdee3;
  padding: 0 30px 0 20px;
  flex-direction: column;
  padding-bottom: 40px;
  padding-top: 56px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;

  width: 100%;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    margin: 0 2px;
    width: 5px;
    background-color: rgb(219, 216, 221);
  }

  ._head {
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    padding: 20px;
    top: -70px;
    background-color: white;
    z-index: 1000;

    h4 {
      font-size: 34px;
      font-weight: bold;
      color: #16284d;
      margin-left: 30px;
    }
  }

  ._mudita-store {
    display: flex;
    align-items: center;
    margin-top: 35px;
    ._title {
      margin-left: 20px;
      h4 {
        font-size: 18px;
        color: #16284d;
        font-weight: bold;
        margin-bottom: 0;
      }

      ._website-status {
        ._not-colored {
          font-size: 16px;
          color: #777c85;
          font-weight: normal;
        }
        ._dot {
          margin: 0 8px;
        }
        ._colored {
          font-size: 16px;
          font-weight: normal;
          color: #3ed0a3;
        }
      }
    }
  }

  ._hold-link {
    display: flex;
    justify-content: center;
    border: 1px solid #dcdee3;
    border-radius: 20px;
    height: 34px;
    align-items: center;
    margin-top: 24px;
    a {
      font-size: 14px;
      color: #16284d;
      font-weight: normal;
      text-decoration: none;
    }
  }

  ._your-items-stocks {
    display: flex;
    padding: 10px 0;
    background: #f6f7f8;
    border-radius: 15px;
    margin-top: 30px;

    ._hold-group-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      ._item {
        background-color: inherit;
        border: none;
        width: 122px;
        text-align: center !important;
        h4 {
          font-size: 14px;
          color: #9aa1af;
          font-weight: normal;
        }
        p {
          font-size: 26px;
          color: #16284d;
          font-weight: bold;
        }
      }
    }
  }
  ._hold-add-bulk-btn {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    ._add-new-item {
      width: 100%;
      height: 48px;
      display: flex;

      align-items: center;
      justify-content: center;
      background: #3ed0a3;
      border-radius: 24px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      border: none;
      margin-bottom: 20px;

      &:disabled {
        background: #b5c4bf !important;
        color: #fff;
        cursor: not-allowed;
      }

      span {
        margin-right: 48px;
      }
    }
    ._add-new-item.text-center {
      width: 100%;
      height: 48px;
      display: flex;

      align-items: center;
      justify-content: center;
      background: #3ed0a3;
      border-radius: 24px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      border: none;
      margin-bottom: 20px;
    }

    ._bulk-btn {
      width: 100%;
      height: 48px;
      background: #e2f8f1;
      border-radius: 24px;
      font-size: 16px;
      color: #3ed0a3;
      font-weight: bold;
      border: none;
    }
  }

  ._hold-catalog {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 35px;
    h2 {
      font-size: 20px;
      color: #16284d;
      font-weight: bold;
      margin-bottom: 20px;
    }

    ._hold-links {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 24px;
      background-color: #f6f7f8;
      padding: 20px 16px;

      a {
        font-size: 16px;
        height: 60px;
        text-decoration: none;
        color: #9aa1af;
        span {
          margin-right: 14px;
        }
      }
    }

    ._add-new-catalog {
      background-color: #f6f7f8;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      height: 72px;
      box-shadow: 0px -1px 11px rgba(22, 40, 77, 0.215909);
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      button {
        width: 100%;
        height: 40px;
        background: #16284d;
        border-radius: 20px;
        border: none;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
      }
    }
  }

  .hold-Product_types {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    background: #f6f7f8;
    border-radius: 15px;
    position: relative;

    ._dropdown {
      padding: 10px;

      h2 {
        font-size: 20px;
        color: #16284d;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }

      .holdType__ {
        background-color: #dcdee344;
        padding: 10px;
        border-radius: 5px;
        color: #16284d;
        font-weight: bold;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr;
        gap: 5px;
        align-items: center;
      }
    }

    ._add-new-catalog {
      background-color: #f6f7f8;
      align-self: flex-end;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      height: 72px;
      box-shadow: 0px -1px 11px rgba(22, 40, 77, 0.215909);
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      button {
        width: 100%;
        height: 40px;
        background: #16284d;
        border-radius: 20px;
        border: none;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
      }
    }
  }

  .activeLink {
    color: #16284d !important;
    font-weight: bold;
    svg {
      path {
        fill: #3ed0a3;
      }
    }
  }
}
